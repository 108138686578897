import { IconButton } from '@shared/components';
import styled from 'styled-components';

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  border: 1px solid ${({ $error, theme }) => ($error ? theme.error : theme.border)};
  box-shadow: ${({ $error, theme }) => $error && theme.shadowInputError};
  border-radius: 2px;
  color: ${({ theme }) => theme.darkest};
  font: inherit;
  outline: none;
  padding: 8px 34px 8px 12px;
  position: relative;
  width: 100%;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border: 1px solid ${({ $error, theme }) => ($error ? theme.error : theme.main)};
    box-shadow: ${({ $error, theme }) => ($error ? theme.shadowInputError : theme.shadowInput)};
  }
`;
export const StyledIconEye = styled(IconButton)`
  top: 12px;
  position: absolute;
  right: 8px;
`;

export const StyledIconValid = styled.span`
  bottom: 12px;
  position: absolute;
  right: 12px;
  width: ${(props) => props.size || 16}px;
  height: ${(props) => props.size || 16}px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.icon});
`;

export const StyledErrorText = styled.p`
  color: ${({ theme }) => theme.error};
  font-size: 12px;
  line-height: 22px;
`;

export const StyledBubble = styled.section`
  background-color: ${({ theme }) => theme.lightest};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 2px;
  bottom: 70px;
  box-shadow: 0 0 4px ${({ theme }) => theme.border};
  font-size: 12px;
  line-height: 22px;
  opacity: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  z-index: 1;

  ${(props) =>
    props.$show &&
    `
    bottom: 75px;
    opacity: 1;
    visibility: visible;
  `}
`;
