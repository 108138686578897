import { StyledEmployeeCardHeader } from './styles';
import { UserActivityRow } from './UserActivityRow';

export const UsersActivityList = ({ employees }) => {
  return (
    <section>
      <StyledEmployeeCardHeader>
        <div></div>
        <div>Действий за сегодня</div>
        <div>Действий за месяц</div>
        <div></div>
      </StyledEmployeeCardHeader>
      {employees?.map((employee) => (
        <UserActivityRow employee={employee} key={employee.id} />
      ))}
    </section>
  );
};
