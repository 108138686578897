import { Container } from '@shared/components';
import React, { Fragment, useMemo, useState } from 'react';
import { HrLine } from '@shared/components/Hr/styles';
import { Avatar } from '@shared/components/Avatar';

import { StyledDepartment, StyledEmployeeCard, StyledName, StyledQuantity, StyledQuantityLabel } from './styles';
import { DetailsButton } from './DetailsButton';

export function UserActivityRow({ employee }) {
  const [activityDetailsModal, setActivityDetailsModal] = useState(false);

  const fullName = useMemo(
    () => `${employee.last_name || ''} ${employee.first_name || ''}`,
    [employee?.last_name, employee?.first_name],
  );

  return (
    <>
      <Fragment key={employee.id}>
        <StyledEmployeeCard>
          <Container gap={'6px'}>
            <Avatar userId={employee.id} />
            <Container direction={'column'}>
              <StyledName>{fullName}</StyledName>
              <StyledDepartment>{employee.department}</StyledDepartment>
            </Container>
          </Container>
          <StyledQuantity>
            <StyledQuantityLabel>Действий за сегодня</StyledQuantityLabel>
            <span>{employee.count_today}</span>
          </StyledQuantity>
          <StyledQuantity>
            <StyledQuantityLabel>Действий за месяц</StyledQuantityLabel>
            <span>{employee.count_month}</span>
          </StyledQuantity>
          <DetailsButton userId={employee.id} />
        </StyledEmployeeCard>
        <HrLine />
      </Fragment>
    </>
  );
}
