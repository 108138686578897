import { SingleSelect } from '@shared/components';
import { Controller } from 'react-hook-form';

const selectOptions = [
  { label: 'По убыванию', value: 'DESC' },
  { label: 'По возрастанию', value: 'ASC' },
];

export function SortingSelect({ control }) {
  return (
    <>
      <Controller
        name="sorting"
        control={control}
        render={({ field }) => (
          <SingleSelect {...field} ref={null} placeholder="Сортировка" options={selectOptions} isClearable />
        )}
      />
    </>
  );
}
