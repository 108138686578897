import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { CustomOption, CustomValue, getOptions } from '@components/form/select/userSelectComponents';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector } from 'react-redux';
import { ProjectStatusWithText } from '@components/chat/styles';
import { Button, Label, SingleSelect } from '@shared/components';
import { date, object } from 'yup';
import {
  StyledPanelButton,
  StyledRequestPanel,
  StyledRequestPanelExpand,
  StyledRequestPanelInfo,
  StyledRequestPanelRow,
  StyledWarning,
} from '@components/chat/request-panel/style';
import iconWarning from 'src/assets/images/icons/warning.svg';

import { formatDateforPicker } from '../../../utilize/helper-functions';
import useAuth from '../../../hooks/useAuth';
import { useMessageContext } from '../../../contexts/MessageContext';

import { RequestDeadline } from './RequestDeadline';

const formSchema = object({
  executor: object().required(),
  deadline_end: date().required(),
});

const HeaderRequestPanel = ({
  projectData,
  getProjectData,
  isRequestPanelOpen,
  setIsRequestPanelOpen,
  currentStatus,
  usersRoles,
  isMobile,
  setChangeExecutor,
  setChangeResponsible,
  changeTaskStatus,
}) => {
  const auth = useAuth();
  const employees = useSelector((state) => state.users.employees);
  const options = useMemo(() => getOptions({ users: employees, token: auth.token }), [employees]);
  const { t } = useTranslation();
  const [isWarningOpen, showIsWarningOpen] = useState(false);
  const [warningText, setWarningText] = useState('');

  // проверяем, было ли последнее сообщение текстовым или это сообщение с историей изменений
  // (например, сообщение об изменении дедлайна, исполнителя и тд)
  // если последнее сообщение было с историей изменений, то должно появляться предупреждение
  const { lastMessage } = useMessageContext();
  const handleEmptyChatWarning = (warning) => {
    if (lastMessage?.action_history) {
      showIsWarningOpen(true);
      setWarningText(warning);

      setTimeout(() => {
        showIsWarningOpen(false);
      }, 3000);

      return false;
    }
    return true;
  };

  // данные для Select компонента executor
  const executor = useMemo(
    () => ({
      name: 'executor',
      label: '',
      type: 'select',
      subType: 'tag',
      options: employees || [],
      value: 'last_name',
      value2: 'first_name',
    }),
    [employees, t],
  );

  const getDefValues = (executor_id) => {
    return {
      executor: executor_id
        ? getOptions({
            users: executor.options,
            token: auth.token,
          }).filter((member) => member.value.id === executor_id)[0]
        : null,
    };
  };

  const currentRequestExecutorId = useSelector((state) => state.projects.currentRequestExecutorId);

  useEffect(() => {
    if (!currentRequestExecutorId) return;
    reset(getDefValues(currentRequestExecutorId));
  }, [currentRequestExecutorId]);

  const { control, reset } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  // показ кнопок в зависимости от ролей/статуса проекта
  const buttonSettings = {
    // просьба создана
    created: [
      { role: 'isExecutor', label: 'Принять', onClick: () => changeTaskStatus('take_on') },
      {
        role: 'isExecutor',
        color: 'yellow',
        label: 'Отправить на уточнение',
        onClick: () => {
          if (!handleEmptyChatWarning('Сперва напишите в чате, что вам не понятно')) return;
          changeTaskStatus('on_clarification');
        },
      },
      {
        role: 'isResponsible',
        color: 'secondary',
        label: 'Отменить',
        onClick: () => changeTaskStatus('cancel'),
      },
    ],
    // просьба отправлена на уточнение
    on_clarification: [
      {
        role: 'isExecutor',
        label: 'Принять',
        onClick: () => changeTaskStatus('take_on'),
      },
      {
        role: 'isResponsible',
        color: 'yellow',
        label: 'Уточнить',
        onClick: () => {
          if (!handleEmptyChatWarning('Сперва напишите в чате пояснение к задаче')) return;
          changeTaskStatus('clarified');
        },
      },
      {
        role: 'isResponsible',
        color: 'secondary',
        label: 'Отменить',
        onClick: () => changeTaskStatus('cancel'),
      },
    ],
    // просьба в работе
    in_progress: [
      {
        role: 'isExecutor',
        color: 'green',
        label: 'Отправить на проверку',
        onClick: () => {
          if (!handleEmptyChatWarning('Сперва добавьте результат работы')) return;
          changeTaskStatus('under_review');
        },
      },
      {
        role: 'isResponsible',
        color: 'secondary',
        label: 'Отменить',
        onClick: () => changeTaskStatus('cancel'),
      },
    ],
    // просьба отправлена на проверку
    under_review: [
      {
        role: 'isResponsible',
        label: 'Принять',
        onClick: () => changeTaskStatus('accept'),
      },
      {
        role: 'isResponsible',
        label: 'Просьбу примет...',
        onClick: () => setChangeResponsible(true),
      },
      {
        role: 'isResponsible',
        color: 'red',
        label: 'На доработку',
        onClick: () => {
          if (!handleEmptyChatWarning('Сперва напишите в чате пояснение к задаче')) return;
          changeTaskStatus('for_revision');
        },
      },
      {
        role: 'isResponsible',
        color: 'secondary',
        label: 'Отменить',
        onClick: () => changeTaskStatus('cancel'),
      },
    ],
    // просьба принята ответственным
    finished: [
      {
        role: 'isResponsible',
        color: 'outlined',
        label: 'Вернуть в работу',
        onClick: () => changeTaskStatus('return'),
      },
    ],
    // просьба отменена ответственным
    canceled: [
      {
        role: 'isResponsible',
        color: 'outlined',
        label: 'Вернуть в работу',
        onClick: () => changeTaskStatus('return'),
      },
    ],
    // просьба просрочена
    overdue: [
      {
        role: 'isExecutor',
        color: 'green',
        label: 'Готово',
        onClick: () => changeTaskStatus('under_review'),
      },
    ],
  };

  // рендер кнопок на основе настроек
  const renderButtons = () => {
    const statusConfig = buttonSettings[currentStatus.isActive];
    if (!statusConfig) return null;

    return statusConfig
      .filter((btn) => usersRoles[btn.role])
      .map((btn, index) => (
        <Button key={index} color={btn.color} onClick={btn.onClick} ref={btn.ref}>
          {btn.label}
        </Button>
      ));
  };

  return (
    <>
      <StyledRequestPanel
        $hide={
          usersRoles.isExecutor && (currentStatus.isActive === 'finished' || currentStatus.isActive === 'canceled')
        }
        $overdue={currentStatus.isActive === 'overdue'}
      >
        {isMobile && (
          <StyledRequestPanelInfo>
            {currentStatus && (
              <ProjectStatusWithText $color={currentStatus.color}>{currentStatus.text}</ProjectStatusWithText>
            )}
            {/*дедлайн для мобилок*/}
            <RequestDeadline
              currentStatus={currentStatus}
              getTaskData={getProjectData}
              taskIdToEdit={projectData?.id}
            />

            <StyledPanelButton
              onClick={() => setIsRequestPanelOpen(!isRequestPanelOpen)}
              $active={isRequestPanelOpen}
            />
          </StyledRequestPanelInfo>
        )}

        {isRequestPanelOpen && (
          <StyledRequestPanelExpand>
            <StyledRequestPanelRow>
              <Label style={{ margin: 0 }}>Исполнитель:</Label>
              <Controller
                name={executor.name}
                control={control}
                render={({ field }) => (
                  <SingleSelect
                    {...field}
                    options={options}
                    components={{ Option: CustomOption, SingleValue: CustomValue }}
                    menuIsOpen={false}
                    isSearchable={false}
                    onFocus={() => setChangeExecutor(true)}
                    width={'280px'}
                  />
                )}
              />
              {renderButtons()}
            </StyledRequestPanelRow>
          </StyledRequestPanelExpand>
        )}
      </StyledRequestPanel>

      {isWarningOpen &&
        ReactDOM.createPortal(
          <StyledWarning icon={iconWarning}>{warningText}</StyledWarning>,
          document.getElementById('warning-root'),
        )}
    </>
  );
};

export default HeaderRequestPanel;
