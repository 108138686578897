import { useMemo, useState } from 'react';
import { isFileNameImage } from 'src/utilize/helper-functions';
import ImageGallery from 'src/windows/images/ImageGallery';

import { StyledAsideSubtitle } from '@components/chat/side-menu/styles';

import useAuth from '../../../../hooks/useAuth';
import { files_url } from '../../../../settings/base-url';
import AttachedFile from '../../../files/AttachedFile';

const SideMenuFiles = ({ files, dataType = 'project', dataId }) => {
  const auth = useAuth();

  // форматировать данные о файлах (присвоить url и подготовить массив галереи изображений)
  const { allAttachedFiles, gallerySourcesArr } = useMemo(() => {
    const gallerySourcesArr = [];

    const urlDataType = dataType === 'office' || dataType === 'project' ? 'projects' : 'tasks';

    const allAttachedFiles = files.map((file) => {
      const urlToGallery = `${files_url}/${urlDataType}/files/${dataId}/${file.file}?token=${auth.token}`;
      const url = `/${urlDataType}/files/${dataId}/${file.file}`;

      if (isFileNameImage(file.file)) {
        gallerySourcesArr.push({
          original: urlToGallery,
          thumbnail: urlToGallery,
          loading: 'lazy',
          alt: file.file,
          imgid: file.id,
        });
      }
      file.url = url;
      return file;
    });
    return { gallerySourcesArr, allAttachedFiles };
  }, [files, dataType, auth?.token]);

  const [openImgId, setOpenImgId] = useState(null);

  if (allAttachedFiles?.length) {
    return (
      <>
        <div>
          <StyledAsideSubtitle> Файлы</StyledAsideSubtitle>
          <div>
            {allAttachedFiles.map((file, i) => {
              return (
                <AttachedFile
                  fileUrl={file.url}
                  setOpenImgId={setOpenImgId}
                  fileName={file.file}
                  fileId={file.id}
                  key={i}
                />
              );
            })}
          </div>
        </div>

        {openImgId && gallerySourcesArr?.length && (
          <ImageGallery imgItems={gallerySourcesArr} openImgId={openImgId} close={() => setOpenImgId(null)} />
        )}
      </>
    );
  }
  return null;
};

export default SideMenuFiles;
