import { css, styled } from 'styled-components';
import { Link } from 'react-router-dom';
import { IconButton, Input } from '@shared/components';

export const StyledUserWriting = styled.p`
  color: ${({ theme }) => theme.dark};
  font-style: italic;
  margin-bottom: 12px;
  font-size: 10px;
  height: 12px;

  @media (min-width: 992px) {
    margin-bottom: 16px;
    font-size: 14px;
  }
`;

export const StyledChatMenuWrapper = styled.div`
  display: flex;
  position: relative;

  @media (max-width: 991px) {
    grid-column: -1/-2;
    grid-row: 1/2;
    justify-self: end;
  }
`;

export const StyledTermWrapper = styled.div`
  @media (max-width: 991px) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
`;

export const StyledChatMenu = styled.section`
  background-color: ${({ theme }) => theme.lightest};
  border: 1px solid ${({ theme }) => theme.borderSecondary};
  border-radius: 4px;
  display: none;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 36px;
  visibility: hidden;
  width: max-content;
  z-index: 100;
  font-size: 12px;
  line-height: 20px;
  height: max-content;

  ${(props) =>
    props.$active &&
    `
    display: unset;
    opacity: 1;
    top: 32px;
    visibility: visible;
  `};
`;

export const StyledChatMenuHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 8px 16px;
`;

export const StyledChatMenuItem = styled.p`
  border: none;
  cursor: pointer;
  display: block;
  padding: 8px 16px;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 100%;

  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.color};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.mainLight};
  }
`;

export const StyledChatMenuSeparator = styled.p`
  border-bottom: 1px solid ${({ theme }) => theme.borderSecondary};
`;

export const StyledChatHeaderWrapper = styled.section`
  background-color: ${({ theme }) => theme.lightest};
  box-shadow: 0 0 4px ${({ theme }) => theme.border};
  position: sticky;
  top: 52px;
  z-index: 2;
`;

export const StyledChatHeader = styled.section`
  gap: 16px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 8px 16px;

  @media (min-width: 768px) {
    padding: 16px;
  }
`;

export const StyledChatLink = styled(Link)`
  color: ${({ theme }) => theme.main};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledChatHeaderCol = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 991px) {
    grid-column: 1/-1;
    display: contents;
  }
`;

export const StyledChatTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  gap: 12px;
  align-items: center;
`;

export const StyledChatBasis = styled.div`
  grid-column: 1/-1;
`;

export const StyledChatHeaderTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledScrollButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.lightest};
  border: none;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0 0 4px rgba(38, 38, 38, 0.3);
  display: flex;
  height: 40px;
  justify-content: center;
  position: fixed;
  left: 16px;
  transition: all 0.3s;
  width: 40px;
  z-index: 3;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px ${({ theme }) => theme.main};

    &::before {
      border-bottom-color: ${({ theme }) => theme.main};
      border-right-color: ${({ theme }) => theme.main};
    }
  }

  &::before {
    border-color: transparent rgba(38, 38, 38, 0.3) rgba(38, 38, 38, 0.3) transparent;
    border-radius: 2px;
    border-style: solid;
    border-width: 2px;
    content: '';
    height: 13px;
    position: absolute;
    top: 12px;
    transform: rotate(45deg);
    transition: all 0.3s;
    width: 13px;
  }

  @media (min-width: 768px) {
    position: absolute;
    bottom: 32px;
    left: 0;
  }
`;

export const StyledChatFooter = styled.section`
  background-color: ${({ theme }) => theme.lightest};
  padding: 8px 59px;
  width: 100%;
  display: grid;

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

export const StyledChatBody = styled.section`
  background-color: ${({ theme }) => theme.lightest};
  display: flex;
  flex-direction: column;
  justify-content: ${({ $flexStart }) => ($flexStart ? 'flex-start' : 'flex-end')};
  overflow-y: auto;
  margin-bottom: 2px;
`;

export const StyledMessagesWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  scrollbar-color: white;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px white;
  }
`;

export const StyledChatInput = styled(Input)`
  margin-bottom: 8px;
  padding: 8px 12px;
  resize: none;
`;

export const StyledChatEditorWrapper = styled.div`
  display: grid;
  grid-template-columns: 42px 1fr 42px;
  gap: 12px;
  align-items: end;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSendButtonMobile = styled(IconButton)`
  width: 42px;
  height: 42px;
  background-color: ${({ theme }) => theme.main};
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  border: none;

  &:hover {
    filter: none;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledMenuButtonMobile = styled(IconButton)`
  width: 42px;
  height: 42px;
  background-color: ${({ theme }) => theme.light};
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  border: none;

  &:hover {
    filter: none;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledChatFooterRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledChatFooterCol = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledChatContextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: ${({ theme }) => theme.light};
  padding: 10px;
`;

export const StyledChatMobileMenu = styled.div`
  position: relative;
  max-height: 42px;
`;

export const StyledChatContextMobile = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: ${({ theme }) => theme.light};
  padding: 10px 6px;
  position: absolute;
  bottom: 100%;
  left: 0;
  border: 1px solid ${({ theme }) => theme.borderSecondary};
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
      visibility: visible;
      bottom: calc(100% + 5px);
    `}
`;

export const StyledChatButtonWrapper = styled.div`
  position: relative;

  & ${StyledChatMenu} {
    bottom: 32px;
    left: 50%;
    top: auto;
    transform: translateX(-50%);

    ${(props) =>
      props.$active &&
      `
      bottom: 40px;
      top: auto;
    `}
  }
`;

export const StyledMenu = styled.ul`
  background-color: ${({ theme }) => theme.lightest};
  border: 1px solid ${({ theme }) => theme.borderSecondary};
  border-radius: 4px;
  bottom: 40px;
  counter-reset: menu;
  left: auto;
  max-width: 280px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: auto;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  width: max-content;

  ${(props) =>
    props.$active &&
    `
    bottom: 48px;
    opacity: 1;
    top: auto;
    visibility: visible;
  `}
`;

export const StyledMenuItem = styled.li`
  cursor: pointer;
  margin-bottom: 8px;
  padding: 8px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.mainLight};
  }
`;

export const StyledMenuHeader = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const StyledMenuBody = styled.div`
  margin-top: 8px;
  max-height: 100px;
  overflow-y: auto;
`;

export const StyledMenuTitle = styled.p`
  font-weight: 500;
  margin-right: 16px;
  position: relative;

  &::before {
    content: counter(menu) '. ';
    counter-increment: menu;
    font-weight: 500;
  }
`;

export const StyledMenuType = styled.p`
  color: ${({ theme }) => theme.dark};
`;

export const ProjectStatus = styled.button`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${(props) => props.color};
  border: none;
  padding: 0;
  vertical-align: middle;
`;

export const ProjectStatusWithText = styled.button`
  border-radius: 2px;
  padding: 1px 8px;
  background-color: ${(props) => props.$color};
  pointer-events: ${(props) => (props.events ? 'auto' : 'none')};
  cursor: pointer;
  border: none;
  vertical-align: middle;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.lightest};
`;

export const StyledDescription = styled.div`
  padding: 20px;
`;
