import React, { useState, useMemo, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DropFilesWrapper from '@components/files/DropFilesWrapper';
import TextEditor from '@components/text-editor/TextEditor';
import FilesUploadManage from '@components/files/FilesUploadManage';

import { Modal } from '@shared/components/Modal/Modal';
import { Label } from '@shared/components/Label/Label';
import { Input } from '@shared/components/Input/Input';
import { Subtitle } from '@shared/components/Subtitle/Subtitle';
import { IconButton } from '@shared/components/IconButton/IconButton';

import iconLock from '@assets/images/icons/lock.svg';
import iconUnlock from '@assets/images/icons/unlocked.svg';

import { Checkbox, SingleSelect } from '@shared/components';
import { StyledModalFlex } from '@shared/components/Modal/style';
import { getFormatCalendarData } from '@shared/components/CalendarForm/CalendarForm';
import Preloader from '@components/preloaders/Preloader';

import useAuth from '../../../hooks/useAuth';
import SelectMulty from '../../../components/form/select/SelectMulty';

import { url_post_created_project, url_put_projects_edit } from '../../../settings/base-url';

import {
  formatReactSelectTagsOptions,
  randomSequence,
  useEventTriggerOnEscPress,
} from '../../../utilize/helper-functions';
import ConfirmAction from '../../../components/warnings/ConfirmAction';
import SnackbarContext from '../../../contexts/SnackbarContext';

import ProjectFormResult from './project-form-result/ProjectFormResult';
import ProjectFormTabs from './ProjectFormTabs';
import { CustomOption, CustomValue, getDefValues, getOptions } from './lib';

const schemaCreateProject = yup.object().shape({
  title_project: yup.string().required(),
  //executor: yup.object().required(),
  responsible: yup.object().required(),
});

const translateKey = 'Project'; // для перевода

const ProjectFormCreateEditBody = ({
  onClose,
  inputs,
  projectData,
  messageBasis,
  getProjectData,
  modalType,
  editable,
  membersTags,
}) => {
  const employees = useSelector((state) => state.users.employees);
  const tagsList = useSelector((state) => state.tags.tagsList);
  const auth = useAuth();
  const { t } = useTranslation();
  // поле описание - храним выделение жирным, курсивом и тд
  const [textFormat, setTextFormat] = useState(null);
  // заблокированные участники и роли
  const [locked, setLocked] = useState(false);
  // цели проекта в графе результат
  const [results, setResults] = useState(
    projectData?.project_goals ? projectData.project_goals.map((goal) => ({ ...goal, goal_id: goal.id })) : [],
  );
  // колонка результата при редактировании
  const [editedItemResult, setEditedItemResult] = useState(null);
  // зеленые вкладки,  открываются по клику на них
  const [formTabs, setFormTabs] = useState({
    result: false,
    deadlines: false,
    tags: false,
    incoming_data: false,
    outgoing_data: false,
  });

  //  при редактировании проекта, раскрыть соответствующие вкладки, если данные в этих вкладках были указаны при создании проекта
  useEffect(() => {
    if (projectData) {
      const formTabs = {
        result: false,
        deadlines: false,
        tags: false,
        incoming_data: false,
        outgoing_data: false,
      };
      if (projectData.project_tags?.length) formTabs.tags = true;
      if (projectData.date_start || projectData.date_finish) formTabs.deadlines = true;
      if (projectData.project_goals?.length) formTabs.result = true;
      for (const tab in formTabs) {
        if (formTabs[tab] === true) {
          setFormTabs(formTabs);
          break;
        }
      }
      setLocked(!!projectData.locked);
    }
  }, [projectData]);

  const formId = useRef(randomSequence());
  const [uploadingFiles, setUploadingFiles] = useState([]);

  const onSubmitFiles = (files) => setUploadingFiles([...files]);

  const [filesDeleteArr, setFilesDeleteArr] = useState([]);
  const removeFilesOnClose = useRef();

  // прикрепленыые файлы проекта, если редактируем
  const { filesAddFormData, filesToEdit, removeFilesReqBody } = useMemo(() => {
    if (!projectData) return {};
    const filesAddFormData = { type: 'project_id', id: projectData.id };
    let filesToEdit, removeFilesReqBody;
    if (projectData.project_files?.length && auth?.token) {
      filesToEdit = projectData.project_files.map((file) => ({
        ...file,
        name: file.file,

        src: `/projects/files/${projectData.id}/${file.file}`,
      }));
      removeFilesReqBody = { project_id: projectData.id };
    }

    return { filesAddFormData, filesToEdit, removeFilesReqBody };
  }, [projectData]);

  const {
    control,
    formState: { isValid, isDirty, errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(schemaCreateProject),
    mode: 'onChange',
    defaultValues: getDefValues(projectData, auth?.user),
  });

  useEffect(() => {
    if (projectData && auth?.token) {
      reset(getDefValues(projectData, auth.token));
    }
  }, [reset, projectData, auth?.token]);

  // добавим колонку результата
  const handlerAddResult = (value, resultId) => {
    // если есть id значит нудно отредачить существующую колонку результата
    if (!resultId) {
      setResults((prev) => [...prev, value]);
    } else {
      const getEditedState = (arr, newItem) => {
        const ind = arr.findIndex(({ id }) => id === newItem.id);
        return [...arr.slice(0, ind), newItem, ...arr.slice(ind + 1)];
      };
      setResults((prev) => getEditedState(prev, value));
      setEditedItemResult(null);
    }
  };
  // назначим редактируемый результат
  const initEditableResult = (value) => {
    setEditedItemResult(value);
  };
  // удалить строку результата
  const deleteResult = (resultToDelete) => {
    setResults((prev) => {
      const resultIndexToDelete = prev.findIndex(({ id }) => id === resultToDelete.id);
      if (resultIndexToDelete === -1) return prev;
      const updResults = [...prev];
      updResults[resultIndexToDelete].toDelete = true;
      return updResults;
    });
  };
  // открыть-закрыть  дополнительные поля формы результат теги и тд
  const handlerTab = (tab) => {
    let item = { ...formTabs };
    let value = (item[`${tab}`] = !formTabs[`${tab}`]);
    setFormTabs(item, value);
  };

  const navigate = useNavigate();

  // отправим на сервер данные формы при создании
  const [isSubmitting, setIsSubmitting] = useState();
  const { showSnackbar } = useContext(SnackbarContext);

  const onSubmitCreate = (data) => {
    // executor_id - ид-исполнителя,
    // responsible_id - ответственного,
    // project_members - массив сотрудников(нужен массив, даже, если 1 сотрудник)

    const body = {
      row: {
        title: data?.title_project || null,
        creator_id: auth?.user?.id,
        description: textFormat,
        locked: locked,
        date_start: data.deadline_start ? getFormatCalendarData(data.deadline_start) : null,
        date_finish: data?.deadline_end ? getFormatCalendarData(data.deadline_end) : null,
        executor_id: data?.executor?.value.id || null,
        responsible_id: data?.responsible?.value.id || null,
        base_chat_message_id: messageBasis ? messageBasis.message_id : null,
        type: modalType,
        chatless: data?.chatless,
      },
      project_members: data?.attendee
        ? data.attendee.map((a) => {
            return { employee_id: a.value.id };
          })
        : [],
      project_tags: data?.tags
        ? data.tags.map((a) => {
            return { tag_id: a.id };
          })
        : [],
      form_id: formId.current,
      project_goals:
        results?.map((res) => {
          return { type: res.type, description: res.description };
        }) || [],
    };

    setIsSubmitting(true);
    axios.post(url_post_created_project, body).then(
      (res) => {
        navigate(`/${modalType}s/${res.data.result}`);
        onClose();
      },
      () => {
        setIsSubmitting(false);
        showSnackbar('Ошибка при создании проекта');
      },
    );
  };

  // отправим на сервер данные формы при редактировании
  const onSubmitEdit = (data) => {
    // executor_id - ид-исполнителя,
    // responsible_id - ответственного,
    // project_members - массив сотрудников(нужен массив, даже, если 1 сотрудник)
    const { description, responsible_id, executor_id, project_members, title, id } = projectData || {};

    const add_project_goals = [],
      edit_project_goals = [],
      delete_project_goals = [];

    for (const result of results) {
      if (result.toDelete) {
        delete_project_goals.push(result.goal_id);
      } else if (result.isEdited) {
        edit_project_goals.push({
          type: result.type,
          description: result.description,
          project_goal_id: result.goal_id,
        });
      } else if (!result.goal_id) {
        add_project_goals.push({
          type: result.type,
          description: result.description,
        });
      }
    }

    const body = {
      project_id: id,
      row: {
        title: data?.title_project || title || null,
        creator_id: auth?.user?.id,
        description: textFormat || description,
        locked: locked,
        date_start: data.deadline_start ? getFormatCalendarData(data.deadline_start) : null,
        date_finish: data?.deadline_end ? getFormatCalendarData(data.deadline_end) : null,
        executor_id: data?.executor?.value.id || executor_id || null,
        responsible_id: data?.responsible?.value.id || responsible_id || null,
        chatless: data?.chatless,
      },
      project_members: data?.attendee
        ? data.attendee.map((a) => {
            return { employee_id: a.value.id };
          })
        : project_members || [],
      add_project_goals,
      edit_project_goals,
      delete_project_goals,
      project_tags: data?.tags
        ? data.tags.map((a) => {
            return { tag_id: a.id };
          })
        : [],
      deleted_files: filesDeleteArr.map((f) => ({ id: f.id, file_name: f.file })),
      form_id: formId.current,
    };
    setIsSubmitting(true);
    axios.put(url_put_projects_edit, body).then(
      () => {
        onClose();
        getProjectData();
        showSnackbar('Проект обновлен', 'success');
      },
      () => {
        setIsSubmitting(false);
        showSnackbar('Возникла ошибка при редактировании проекта');
      },
    );
  };

  const [modalClosePrompt, setModalClosePrompt] = useState(false);

  const handlerClose = (e) => {
    if (e) e.preventDefault();
    if (
      isDirty ||
      uploadingFiles.length ||
      (!projectData && results?.length) ||
      (projectData && results?.some((result) => result.isEdited || !result.goal_id)) ||
      textFormat
    ) {
      setModalClosePrompt(true);
    } else onClose();
  };

  // регистрация eventa keypress для выхода из модального окна в случае нажатии на кнопку Esc
  useEventTriggerOnEscPress(handlerClose);

  const { description, attendee, responsible, tags } = useMemo(() => {
    if (!inputs) {
      return {
        title: {
          name: 'title_project',
          label: t(`Project.title`) + '*',
          type: 'text',
        },
        description: {
          name: 'description',
          label: t(`Project.description`),
          type: 'text',
        },
        attendee: {
          name: 'attendee',
          label: '',
          // label: t(`Project.attendee_role`),
          type: 'select',
          subType: 'tag',
          options: getOptions({ users: employees, token: auth.token }),
        },
        responsible: {
          name: 'responsible',
          label: t(`Project.responsible`) + '*',
          type: 'select',
          subType: 'tag',
          options: employees || [],
        },
        /* временно убрали этот функционал */
        // executor: {
        //   name: 'executor',
        //   // label: "",
        //   label: t(`Project.executor`) + '*',
        //   type: 'select',
        //   subType: 'tag',
        //   options: employees || [],
        // },
        deadlines: {
          label: t(`Project.deadlines`),
          deadline_start: {
            name: 'deadline_start',
            placeholder: t('Project.start_date'),
          },
          deadline_end: {
            name: 'deadline_end',
            placeholder: t('Project.end_date'),
          },
        },
        tags: {
          name: 'tags',
          label: '',
          // placeholder:'Выбрать тэги',
          // label: t(`Project.attendee_role`),
          type: 'select',
          subType: 'tag',
          options: formatReactSelectTagsOptions(tagsList),
        },
      };
    } else
      return {
        ...inputs,
        attendee: { ...inputs.attendee, options: getOptions({ users: employees, token: auth.token }) },
      };
  }, [inputs, t, employees, tagsList]);

  const options = useMemo(() => getOptions({ users: employees, token: auth.token }), [employees]);
  const messageBasisEl = useMemo(() => {
    if (messageBasis) {
      return (
        <div>
          {t('Project.message_basis')}
          <strong>{` ${messageBasis.author} `}</strong>
          <div>
            {t('common.date') + ' '}
            <strong>{messageBasis.LocalDateTime}</strong>
          </div>
        </div>
      );
    } else return null;
  }, [messageBasis, t]);

  const confirmText = isSubmitting
    ? t('FormButtons.sending')
    : projectData
    ? t('FormButtons.save')
    : t('FormButtons.create');

  const title = useMemo(() => {
    let title = '';

    if (editable) {
      if (modalType === 'office') {
        title = 'Редактировать офис';
      } else if (modalType === 'project') {
        title = t(`${translateKey}.title_edit`);
      }
    } else {
      if (modalType === 'office') {
        title = 'Создать офис';
      } else if (modalType === 'project') {
        title = t(`${translateKey}.title_create`);
      }
    }

    return title;
  }, [projectData, modalType]);

  return (
    <Modal
      title={title}
      onClose={handlerClose}
      confirmButtonText={confirmText}
      disabledSaveButton={!isValid || isSubmitting}
      onSave={handleSubmit(projectData ? onSubmitEdit : onSubmitCreate)}
    >
      {(editable && !projectData) || (!editable && !membersTags) ? (
        <Preloader />
      ) : (
        <>
          {messageBasisEl}
          <DropFilesWrapper setFiles={setUploadingFiles}>
            <form onSubmit={handleSubmit(projectData ? onSubmitEdit : onSubmitCreate)}>
              <div>
                <Label htmlFor="name">
                  {t('common.title_name')} <sup>*</sup>
                </Label>

                <Input
                  error={!!errors['title_project']}
                  id="name"
                  register={register('title_project')}
                  placeholder={t('common.enter_title')}
                />
              </div>
              {/* описание  */}
              <div>
                <Label htmlFor="description">{t('Project.description')}</Label>
                <TextEditor
                  prev={description.prev}
                  sendFormat={setTextFormat}
                  name={description.name} // name нужно для настройки react hook form
                  height={114}
                  projectId={projectData?.id}
                  formId={formId}
                  onSubmitFiles={onSubmitFiles}
                  messageBasis={messageBasis}
                />
                {(uploadingFiles.length > 0 || filesToEdit?.length) && (
                  <FilesUploadManage
                    filesToUpload={uploadingFiles}
                    filesToEdit={filesToEdit}
                    filesDeleteArr={filesDeleteArr}
                    setFilesDeleteArr={setFilesDeleteArr}
                    formId={formId.current}
                    removeFilesOnClose={removeFilesOnClose}
                    removeFilesReqBody={removeFilesReqBody}
                    filesUploadPath="/api/projects/files/add"
                    filesDeletePath="/api/projects/files/remove"
                    filesAddFormData={filesAddFormData}
                  />
                )}
                {/* список загруженных файлов */}
                {/* {fileList.length > 0 && <DragFileList list={fileList} onRemoveFile={removeFile} />} */}
              </div>

              <div>
                <Controller
                  control={control}
                  name="chatless"
                  render={({ field }) => (
                    <Checkbox id="chatless" checked={field.value} onChange={field.onChange}>
                      Без чата
                    </Checkbox>
                  )}
                />
              </div>

              {/* Участники и роли */}
              <div>
                <StyledModalFlex>
                  <Subtitle>{t(`${translateKey}.members_roles`)}</Subtitle>
                  <IconButton icon={locked ? iconLock : iconUnlock} onClick={() => setLocked(!locked)}></IconButton>
                </StyledModalFlex>

                <SelectMulty
                  item={attendee}
                  control={control}
                  options={attendee.options}
                  customOption={CustomOption}
                  getOptionValue={(option) => option.value.id}
                />
              </div>

              {/* ответсвенный и исполнитель */}
              {/*<StyledModalRowStart>*/}
              <div>
                <Label>
                  {t(`${translateKey}.responsible`)} <sup>*</sup>
                </Label>
                <Controller
                  name={responsible.name}
                  control={control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      options={options}
                      components={{ Option: CustomOption, SingleValue: CustomValue }}
                    />
                  )}
                />
              </div>

              {/* временно убрали этот функционал */}
              {/*<div>*/}
              {/*  <Label>*/}
              {/*    {t(`${translateKey}.executor`)} <sup>*</sup>*/}
              {/*  </Label>*/}
              {/*  <Controller*/}
              {/*    name={executor.name}*/}
              {/*    control={control}*/}
              {/*    render={({ field }) => (*/}
              {/*      <SingleSelect*/}
              {/*        {...field}*/}
              {/*        options={options}*/}
              {/*        components={{ Option: CustomOption, SingleValue: CustomValue }}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*</StyledModalRowStart>*/}

              {/* сроки результат и тд вкладки разворачивающиеся  при клике */}
              <ProjectFormTabs handler={handlerTab} tabs={formTabs} />

              {/* результат */}
              {formTabs.result && (
                <ProjectFormResult
                  tab="result"
                  results={results}
                  setResult={handlerAddResult}
                  initEditableResult={initEditableResult}
                  editedItemResult={editedItemResult}
                  deleteResult={deleteResult}
                />
              )}

              {/* временно убрали этот функционал */}
              {/* сроки  */}
              {/*{formTabs.deadlines && (*/}
              {/*  <div>*/}
              {/*    <StyledModalFlex>*/}
              {/*      <Subtitle>{deadlines.label}</Subtitle>*/}
              {/*    </StyledModalFlex>*/}
              {/*    <StyledModalRowStart>*/}
              {/*      <CalendarForm data={deadlines.deadline_start} control={control} prev={projectData?.date_start} />*/}
              {/*      <CalendarForm data={deadlines.deadline_end} control={control} prev={projectData?.date_finish} />*/}
              {/*    </StyledModalRowStart>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/* тэги */}
              {formTabs.tags && (
                <div>
                  <StyledModalFlex>
                    <Subtitle>{t('common.tags')}</Subtitle>
                  </StyledModalFlex>
                  <SelectMulty item={tags} control={control} options={tags.options} />
                </div>
              )}
            </form>
          </DropFilesWrapper>
        </>
      )}

      {modalClosePrompt && (
        <ConfirmAction
          cancel={() => setModalClosePrompt(false)}
          confirm={() => {
            if (typeof removeFilesOnClose.current === 'function') {
              removeFilesOnClose.current(projectData && { project_id: projectData.id });
            }
            onClose();
          }}
          actionText="Уверены что хотите закрыть окно без сохранения изменений?"
        />
      )}
    </Modal>
  );
};

export default ProjectFormCreateEditBody;
