import Preloader from '@components/preloaders/Preloader';
import axios from 'axios';
import { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'src/contexts/AuthContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import useAuth from 'src/hooks/useAuth';

export function TelegramLoginPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirect = useCallback(() => {
    const redirectUrl = searchParams.get('redirect');
    if (redirectUrl) navigate(redirectUrl);
    else navigate('/');
  }, [searchParams]);

  const { setUser, setToken } = useContext(AuthContext);

  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const fetchData = async () => {
      if (auth?.token) redirect();
      else {
        try {
          await require('https://telegram.org/js/telegram-web-app.js');
        } catch {
          showSnackbar('Не удалось загрузить Telegram данные для автоматической авторизации');
        }
        if (window.Telegram?.WebApp?.initData) {
          await axios
            .post('/api/sign_in_telegram', { init_data: window.Telegram.WebApp.initData })
            .then((response) => {
              if (!response.data?.token) throw new Error('no token');
              setUser({
                name: response.data.first_name || '',
                surname: response.data.last_name || '',
                id: response.data.user_id,
                company_id: response.data.company_id,
                rights: response.data.rights,
                is_guest: response.data.is_guest,
              });
              setToken(response.data.token);
            })
            .catch(() => {
              showSnackbar('Не удалось авторизоваться с помощью Telegram ника');
              redirect();
            });
        } else redirect();
      }
    };

    fetchData();
  }, [auth?.token, redirect]);

  return <Preloader />;
}
