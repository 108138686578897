import { Controller } from 'react-hook-form';
import { Button, Search } from '@shared/components';
import { useCallback } from 'react';

import { useGetContactsFilterDataQuery } from 'src/redux/features/api/contacts';

import DepartmentSelect from '../DepartmentsSelect';

import { StyledSubheader, Wrapper } from './styles';
import { RightsSelect } from './RightsSelect';

export const UsersRightsFilter = ({ control, handleSubmit, submitRequest, setValue }) => {
  const { data } = useGetContactsFilterDataQuery();

  const handleShowButtonClick = useCallback(() => {
    setValue('page', 1);
    handleSubmit(submitRequest)();
  }, [handleSubmit, submitRequest]);

  return (
    <Wrapper>
      <StyledSubheader gridTemplate={'repeat(4, 1fr)'}>
        <RightsSelect control={control} />
        <DepartmentSelect control={control} departments={data?.departments} />

        <Controller
          name="employee"
          control={control}
          render={({ field }) => {
            return (
              <Search
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                value={field.value}
                id="employee"
              />
            );
          }}
        />

        <Button color="green" position="left" size={20} onClick={handleShowButtonClick}>
          Показать
        </Button>
      </StyledSubheader>
    </Wrapper>
  );
};
