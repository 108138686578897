import { StyledLayout } from '@shared/components/Layout/styles';
import { useLazyGetUsersRightsQuery } from 'src/redux/features/api/control';
import Preloader from '@components/preloaders/Preloader';
import Pagination from '@shared/components/Pagination';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { UsersRightsList } from './UsersRightsList';
import { UsersRightsFilter } from './UsersRightsFilter';
import { StyledTitle, StyledTitleWrapper } from './styles';

export const UsersRights = () => {
  const [getUsersRights, { data, isFetching }] = useLazyGetUsersRightsQuery();

  const { control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      right: null,
      department: null,
      employee: '',
      page: 1,
    },
  });

  const submitRequest = useCallback(
    (values) => {
      const requestBody = {
        right: values.right?.value,
        employee: values.employee,
        department_id: values.department?.value,
        page: values.page,
      };
      getUsersRights(requestBody);
    },
    [getUsersRights],
  );

  const changePage = useCallback(
    (pageNumber) => {
      setValue('page', pageNumber);
      handleSubmit(submitRequest)();
    },
    [setValue, handleSubmit, submitRequest],
  );

  useEffect(() => {
    getUsersRights();
  }, [getUsersRights]);

  return (
    <>
      <StyledLayout>
        <StyledTitleWrapper>
          <StyledTitle>Права сотрудников</StyledTitle>
        </StyledTitleWrapper>

        <UsersRightsFilter
          control={control}
          handleSubmit={handleSubmit}
          submitRequest={submitRequest}
          setValue={setValue}
        />

        {isFetching ? (
          <Preloader />
        ) : (
          <>{data?.employee_rights?.employees && <UsersRightsList employees={data.employee_rights.employees} />}</>
        )}

        {data?.employee_rights?.page_count && (
          <Pagination id={1} totalPages={data.employee_rights.page_count} onChange={changePage} defaultValue={1} />
        )}
      </StyledLayout>
    </>
  );
};
