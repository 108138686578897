import { useEffect, useState, useCallback, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { StyledChatBody, StyledDescription, StyledMessagesWrapper } from '@components/chat/styles';
import { StyledChatMessage } from '@components/chat/message/styles';
import { updateOldMessage } from 'src/redux/features/messagesSlice';
import { useSocketContext } from 'src/contexts/SocketContext';
import { store } from 'src/redux/store';
import HTMLReactParser from 'html-react-parser';

import { useMessageContext } from '../../contexts/MessageContext';
import Preloader from '../preloaders/Preloader';
import DropFilesWrapper from '../files/DropFilesWrapper';

import MessageContainer from './message/MessageContainer';
import MessageTextSelectionPopover from './MessageTextSelectionPopover';
import OldMessagesViewer from './OldMessagesViewer';
import SingleProjectChatFooter from './SingleProjectChatFooter';
import { handleMessageActionHistory } from './lib';

export default memo(SingleProjectChat);

function SingleProjectChat({
  projectData,
  messagesList,
  setMessagesList,
  setPinnedMessages,
  messagesLoading,
  nextMessagesLoading,
  resetToLatestMessages,
  scrollToEndButton,
  chatPageRef,
  // guestRights,
  handleScroll,
  scrollToLastMessage,
}) {
  console.log('%c [ messagesList ]-27', 'font-size:13px; background:#ccc; color:blue;', messagesList);
  const { socket, addToRoom, onChatChange, offChatChange } = useSocketContext();
  const { setLastMessage } = useMessageContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // войти в комнату socket для чата
  useEffect(() => {
    if (socket?.connected && projectData?.chat?.id) {
      addToRoom(`chat_room_${projectData.chat.id}`);

      // ловим последнее сообщение для просьб
      const messages = projectData.chat.messages;
      const lastMessage = messages[messages.length - 1];
      setLastMessage(lastMessage);
    }
  }, [socket, projectData, addToRoom]);

  // для прикрепления файлов при отправке сообщения с файлами
  const [draggedFiles, setDraggedFiles] = useState();

  // переменные для перехода в старые сообщения
  const { requestedOldMessageId, loadingOldMessages, oldMessages } = useSelector((state) => state.messages);
  // функция для обновления чата при получении сообщений из сокета
  const chatChangeListener = useCallback(
    (data, action) => {
      if (projectData?.chat?.id) {
        // ловим последнее сообщение для просьб
        setLastMessage(data);

        switch (action) {
          case 'added':
            if (data.action_history) handleMessageActionHistory(data.action_history);
          case 'poll_added':
            if (data.chat_id === projectData.chat.id) {
              setMessagesList((messages) => [
                ...messages,
                <MessageContainer key={data.id} {...data} goals={data.chat_goal_results} projectData={projectData} />,
              ]);

              if (store.getState().messages.isScrolledChatBottom) {
                setTimeout(() => {
                  const newMessage = document.getElementById(`messageId${data.id}`);
                  if (newMessage) {
                    newMessage.scrollIntoView();
                  }
                }, 100);
              }
            }
            break;

          case 'edited':
          case 'poll_edited':
          case 'like':
          case 'dislike':
          case 'cancel_like_dislike':
          case 'recovered':
          case 'goal_accept':
            if (data.chat_id === projectData.chat.id) {
              setMessagesList((messagesList) => {
                const foundIndex = messagesList.findIndex((message) => message.props.id === data.id);
                if (foundIndex === -1) return messagesList;
                const updMessages = [...messagesList];

                updMessages[foundIndex] = (
                  <MessageContainer key={data.id} {...data} goals={data.chat_goal_results} projectData={projectData} />
                );
                return updMessages;
              });

              setPinnedMessages((pinnedMessages) => {
                const foundPinndeMessageIndex = pinnedMessages.findIndex((message) => message.props.id === data.id);
                if (foundPinndeMessageIndex === -1) return pinnedMessages;
                const updMessages = [...pinnedMessages];
                updMessages[foundPinndeMessageIndex] = (
                  <MessageContainer
                    key={data.id}
                    {...data}
                    goals={data.chat_goal_results}
                    projectData={projectData}
                    pinnedMessageType={true}
                  />
                );
                return updMessages;
              });

              dispatch(updateOldMessage({ data }));
            }
            break;

          case 'pinned':
            if (data.chat_id === projectData.chat.id) {
              setMessagesList((messagesList) => {
                const foundIndex = messagesList.findIndex((message) => message.props.id === data.id);
                if (foundIndex === -1) return messagesList;
                const updMessages = [...messagesList];
                updMessages[foundIndex] = (
                  <MessageContainer key={data.id} {...data} goals={data.chat_goal_results} projectData={projectData} />
                );
                return updMessages;
              });
              setPinnedMessages((pinnedMessages) => [
                ...pinnedMessages,
                <MessageContainer
                  key={data.id}
                  {...data}
                  goals={data.chat_goal_results}
                  projectData={projectData}
                  pinnedMessageType={true}
                />,
              ]);

              dispatch(updateOldMessage({ data, type: action }));
            }
            break;

          case 'unpinned':
            setPinnedMessages((pinnedMessages) =>
              pinnedMessages.filter((pinnedMessage) => pinnedMessage.props.id !== data.id),
            );
            setMessagesList((messagesList) => {
              const unpinnedMessageIndex = messagesList.findIndex((message) => message.props.id === data.id);
              if (unpinnedMessageIndex === -1) return messagesList;
              const updMessages = [...messagesList];
              updMessages[unpinnedMessageIndex] = (
                <MessageContainer key={data.id} {...data} goals={data.chat_goal_results} projectData={projectData} />
              );
              return updMessages;
            });
            dispatch(updateOldMessage({ data, type: action }));
            break;

          case 'deleted':
            setMessagesList((messagesList) => {
              const messageIndexToDelete = messagesList.findIndex((message) => message.props.id === data.id);
              if (messageIndexToDelete === -1) return messagesList;
              const updMessages = [...messagesList];
              updMessages[messageIndexToDelete] = (
                <MessageContainer key={data.id} {...updMessages[messageIndexToDelete].props} deleted={1} />
              );
              return updMessages;
            });

            setPinnedMessages((pinnedMessages) => {
              const pinnedMessageIndexToDelete = pinnedMessages.findIndex((message) => message.props.id === data.id);
              if (pinnedMessageIndexToDelete === -1) return pinnedMessages;
              const updPinnedMessages = [...pinnedMessages];
              updPinnedMessages[pinnedMessageIndexToDelete] = (
                <MessageContainer
                  key={data.id}
                  {...updPinnedMessages[pinnedMessageIndexToDelete].props}
                  deleted={1}
                  pinnedMessageType={true}
                />
              );
              return updPinnedMessages;
            });

            dispatch(updateOldMessage({ data, type: action }));

            break;
          default:
            return;
        }
      }
    },
    [projectData, setMessagesList, setPinnedMessages, chatPageRef, dispatch],
  );

  // cлушать за изменением чата со стороны других пользователей
  useEffect(() => {
    if (socket && socket.connected) {
      onChatChange(chatChangeListener);

      return () => offChatChange();
    }
  }, [socket, chatChangeListener]);

  const [disableChatFilesDrop, setDisableChatFilesDrop] = useState();

  const noMessages = useMemo(() => {
    return Boolean(projectData.chatless) && Array.isArray(messagesList) && messagesList.length === 0;
  }, [projectData?.chatless]);

  return (
    <DropFilesWrapper setFiles={setDraggedFiles} disabled={disableChatFilesDrop}>
      <StyledChatBody $flexStart={projectData.chatless}>
        {/*для предупреждения в просьбах*/}
        <div id="warning-root" style={{ position: 'relative' }}></div>

        <StyledMessagesWrapper onScroll={handleScroll} ref={chatPageRef}>
          {!projectData.chatless && <div style={{ height: '100%' }}></div>}
          {requestedOldMessageId ? (
            <>
              {/* компонент используется для показа старых сообщений (не из актуального feed) с двухсторонней прокруткой */}
              <OldMessagesViewer
                requestedOldMessageId={requestedOldMessageId}
                oldMessages={oldMessages}
                projectData={projectData}
                loadingOldMessages={loadingOldMessages}
                messagesLoading={messagesLoading}
                nextMessagesLoading={nextMessagesLoading}
              />
            </>
          ) : (
            <>
              {messagesLoading === 'previous' && <Preloader />}

              {/* если самое начало чата, то показывает сообщение "больше нет сообщений в чате" */}
              {messagesLoading === 'end' && <StyledChatMessage>{t('Chat.no_more')}</StyledChatMessage>}

              {/* список актуальных, последних сообщений */}
              {messagesList}

              {Boolean(projectData.chatless) && (
                <StyledDescription>
                  {typeof projectData.description === 'string' && HTMLReactParser(projectData.description)}
                </StyledDescription>
              )}
            </>
          )}
          {/* показывает контекстное меню при выделении текста в любом сообщении */}
          <MessageTextSelectionPopover />
        </StyledMessagesWrapper>
      </StyledChatBody>

      {!projectData.chatless && (
        <SingleProjectChatFooter
          scrollToEndButton={scrollToEndButton}
          requestedOldMessageId={requestedOldMessageId}
          resetToLatestMessages={resetToLatestMessages}
          scrollToLastMessage={scrollToLastMessage}
          projectData={projectData}
          setDisableChatFilesDrop={setDisableChatFilesDrop}
          draggedFiles={draggedFiles}
        />
      )}
    </DropFilesWrapper>
  );
}
