export const rightsRussianLabels = {
  leader: 'Лидер',
  links_edit: 'Редактирование ссылок',
  contacts_view: 'Просмотр контактов',
  contacts_edit: 'Редактирование контактов',
  team_edit: 'Редактирование команды',
  team_hidden_fields: 'Просмотр скрытых полей у команды',
  team_search_view: 'Просмотр поиска команды',
  team_search_edit: 'Редактирование поиска команды',
  salary_view: 'Просмотр заработной платы',
  payouts_view: 'Просмотр выплат',
  payouts_edit: 'Редактирование выплат',
  positions_edit: 'Редактирование должностей',
  departments_edit: 'Редактирование отделов',
  news_add: 'Создание новостей',
  storage_create: 'Создание хранилищ',
  developer: 'Разработчик',
  project_create: 'Создание проекта',
  survey_create: 'Создание опроса',
};
