import { Container } from '@shared/components';
import React, { Fragment, useMemo, useState } from 'react';
import { HrLine } from '@shared/components/Hr/styles';
import { Avatar } from '@shared/components/Avatar';

import { StyledDepartment, StyledEmployeeCard, StyledName, StyledOverdue, StyledQuantityLabel } from './styles';
import { isEngaged } from './lib';
import { DetailsButton } from './DetailsButton';

export function UserEngagementRow({ employee }) {
  const [activityDetailsModal, setActivityDetailsModal] = useState(false);

  const fullName = useMemo(
    () => `${employee.last_name || ''} ${employee.first_name || ''}`,
    [employee?.last_name, employee?.first_name],
  );

  return (
    <>
      <Fragment key={employee.id}>
        <StyledEmployeeCard>
          <Container gap={'6px'}>
            <Avatar userId={employee.id} />
            <Container direction={'column'}>
              <StyledName>{fullName}</StyledName>
              <StyledDepartment>{employee.department}</StyledDepartment>
            </Container>
          </Container>
          <div>
            {isEngaged(employee.request) && (
              <>
                {<StyledQuantityLabel>Просьбы</StyledQuantityLabel>}
                {Boolean(employee.request.members) && (
                  <div>
                    Участник: <span>{employee.request.members}</span>
                  </div>
                )}

                {Boolean(employee.request.creator) && (
                  <div>
                    Создатель: <span>{employee.request.creator}</span>
                  </div>
                )}

                {Boolean(employee.request.responsible) && (
                  <div>
                    Ответственный: <span>{employee.request.responsible}</span>
                  </div>
                )}

                {Boolean(employee.request.executor) && (
                  <div>
                    Исполнитель: <span>{employee.request.executor}</span>
                  </div>
                )}

                {Boolean(employee.request.overdue?.clarification || employee.request.overdue?.execution) && (
                  <>
                    <br />
                    <StyledOverdue>
                      <div>Просрочено</div>
                      <ul>
                        {Boolean(employee.request.overdue.execution) && (
                          <li>в исполнении: {employee.request.overdue.execution}</li>
                        )}
                        {Boolean(employee.request.overdue.clarification) && (
                          <li>в постановке: {employee.request.overdue.clarification} </li>
                        )}
                      </ul>
                    </StyledOverdue>
                  </>
                )}
              </>
            )}
          </div>

          <div>
            {isEngaged(employee.rubric) && (
              <>
                <StyledQuantityLabel>Разделы</StyledQuantityLabel>
                {Boolean(employee.rubric.members) && (
                  <div>
                    Участник: <span>{employee.rubric.members}</span>
                  </div>
                )}

                {Boolean(employee.rubric.creator) && (
                  <div>
                    Создатель: <span>{employee.rubric.creator}</span>
                  </div>
                )}

                {Boolean(employee.rubric.responsible) && (
                  <div>
                    Ответственный: <span>{employee.rubric.responsible}</span>
                  </div>
                )}

                {Boolean(employee.rubric.executor) && (
                  <div>
                    Исполнитель: <span>{employee.rubric.executor}</span>
                  </div>
                )}
              </>
            )}
          </div>

          <div>
            {isEngaged(employee.project) && (
              <>
                <StyledQuantityLabel>Проекты</StyledQuantityLabel>
                {Boolean(employee.project.members) && (
                  <div>
                    Участник: <span>{employee.project.members}</span>
                  </div>
                )}

                {Boolean(employee.project.creator) && (
                  <div>
                    Создатель: <span>{employee.project.creator}</span>
                  </div>
                )}

                {Boolean(employee.project.responsible) && (
                  <div>
                    Ответственный: <span>{employee.project.responsible}</span>
                  </div>
                )}

                {Boolean(employee.project.executor) && (
                  <div>
                    Исполнитель: <span>{employee.project.executor}</span>
                  </div>
                )}
              </>
            )}
          </div>

          <div>
            {isEngaged(employee.office) && (
              <>
                <StyledQuantityLabel>Офисы</StyledQuantityLabel>
                {Boolean(employee.office.members) && (
                  <div>
                    Участник: <span>{employee.office.members}</span>
                  </div>
                )}

                {Boolean(employee.office.creator) && (
                  <div>
                    Создатель: <span>{employee.office.creator}</span>
                  </div>
                )}

                {Boolean(employee.office.responsible) && (
                  <div>
                    Ответственный: <span>{employee.office.responsible}</span>
                  </div>
                )}

                {Boolean(employee.office.executor) && (
                  <div>
                    Исполнитель: <span>{employee.office.executor}</span>
                  </div>
                )}
              </>
            )}
          </div>

          <DetailsButton userId={employee.id} />
        </StyledEmployeeCard>
        <HrLine />
      </Fragment>
    </>
  );
}
