import { updateCurrentProject } from 'src/redux/features/projectsSlice';
import { store } from 'src/redux/store';

const setProjectStatusInProgress = () => store.dispatch(updateCurrentProject({ currentProjectStatus: 'in_progress' }));

const messageActionTypes = {
  request_for_revision: setProjectStatusInProgress,
  request_returned: setProjectStatusInProgress,
  request_take_on: setProjectStatusInProgress,
  request_canceled() {
    store.dispatch(updateCurrentProject({ currentProjectStatus: 'canceled' }));
  },
  request_under_review() {
    store.dispatch(updateCurrentProject({ currentProjectStatus: 'under_review' }));
  },
  request_accepted() {
    store.dispatch(updateCurrentProject({ currentProjectStatus: 'finished' }));
  },
  change_request_deadline(actionHistory) {
    if (typeof actionHistory?.additional_data?.after === 'string') {
      store.dispatch(updateCurrentProject({ currentRequestDeadline: actionHistory.additional_data?.after }));
    }
  },
  change_request_executor(actionHistory) {
    if (typeof actionHistory?.additional_data?.after?.id === 'number') {
      store.dispatch(updateCurrentProject({ currentRequestExecutorId: actionHistory.additional_data.after.id }));
    }
  },
  request_accepting_redirect(actionHistory) {
    if (typeof actionHistory?.additional_data?.after?.id === 'number') {
      store.dispatch(updateCurrentProject({ currentProjectResponsibleId: actionHistory.additional_data.after.id }));
    }
  },
};

export function handleMessageActionHistory(actionHistory) {
  if (typeof messageActionTypes[actionHistory.action_type] === 'function') {
    messageActionTypes[actionHistory.action_type](actionHistory);
  }
}
