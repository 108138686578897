import { useEffect, useState, useMemo, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  StyledAsideBackwardButton,
  StyledAsideBody,
  StyledAsideHeader,
  StyledAsideLink,
  StyledAsideParentTask,
  StyledAsideSubtitle,
  StyledAsideTitle,
} from '@components/chat/side-menu/styles';
import iconMembers from '@assets/images/icons/subtask-profile.svg';
import { IconButton } from '@shared/components';
import iconClose from '@assets/images/icons/close.svg';

import UserProfileModalContainer from '../../../windows/profile/UserProfileModalContainer';
import Preloader from '../../preloaders/Preloader';

import GuestModalCurrent from '../../../windows/profile/GuestModalCurrent';
import CreateEditGuestModal from '../../../windows/profile/CreateEditGuestModal';

import ProjectTree from './ProjectTree';
import AsideUserCard from './sideMenuComponents/AsideUserCard';
import { useProjectType } from './useProjectType';

const MembersSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const { t } = useTranslation();

  const [panelData, setPanelData] = useState();
  const [projectTree, showProjectTree] = useState(false);
  const [userProfile, showUserProfile] = useState();
  const [guestProfile, showGuestProfile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const fetchData = async (dataType, dataId, title) => {
    let projectTypeToRequest = null;
    if (dataType === 'office' || dataType === 'project') {
      projectTypeToRequest = 'project';
    } else if (dataType === 'request' || dataType === 'rubric' || dataType === 'task') {
      projectTypeToRequest = 'task';
    }
    if (!projectTypeToRequest) return;
    setIsLoading(true);
    setErrorLoading(false);

    try {
      const response = await axios.get(`/api/sidebar_members/${projectTypeToRequest}/${dataId}`);
      if (response.data.project) {
        setPanelData({ ...response.data.project, title });
      } else if (response.data.task) {
        setPanelData({ ...response.data.task, title });
      }
    } catch (e) {
      setErrorLoading(true);
    } finally {
      setIsLoading(false);
    }
  };

  // reset данных при закрытии панели
  useEffect(() => {
    if (sideMenu !== 'people' && (panelData || projectTree)) {
      setTimeout(() => {
        setPanelData(null);
        showProjectTree(null);
        setSidebarDataType(taskId ? 'task' : 'project');
      }, 1000);
    }
  }, []);

  // получить данные по участникам, при первоначальном открытии этого сайдбара
  useEffect(() => {
    if (
      (!taskId && !projectId) ||
      !projectData ||
      (taskId && projectData.id !== +taskId) ||
      (!taskId && projectData.id !== +projectId)
    ) {
      return;
    }
    if (sideMenu === 'people') {
      const dataType = taskId ? 'task' : 'project';
      fetchData(dataType, projectData.id, projectData.title);
    }
  }, [sideMenu, projectData, taskId, projectId]);

  const [sidebarDataType, setSidebarDataType] = useState(null);

  useEffect(() => {
    if (!projectData?.type) return;
    setSidebarDataType(projectData?.type);
  }, [projectData?.type]);

  const showRelevantTaskInfo = (dataType, dataId, title) => {
    showProjectTree(false);
    setSidebarDataType(dataType);
    fetchData(dataType, dataId, title);
  };

  const {
    creator_first_name,
    creator_last_name,
    creator_id,
    responsible_first_name,
    responsible_last_name,
    responsible_id,
    executor_first_name,
    executor_last_name,
    executor_id,
  } = useMemo(() => {
    if (panelData) return panelData;
    return {};
  }, [panelData]);

  const renderMembers = (members) => {
    if (!members || !members.length) return null;
    return (
      <>
        <StyledAsideSubtitle>{t('Project.members')}</StyledAsideSubtitle>

        {members.map((member, i) => {
          const { first_name, last_name } = member;

          return (
            <AsideUserCard
              key={i}
              userId={member.id}
              lastName={last_name}
              firstName={first_name}
              showUserProfile={showUserProfile}
            />
          );
        })}
      </>
    );
  };

  const [addGuestModal, showAddGuestModal] = useState();

  const projectType = useProjectType({ projectData, sidebarDataType });

  return (
    <>
      {/* Показать профиль участника, если нажали на соответствующую ссылку */}
      {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}

      {/* Показать профиль участника, если нажали на соответствующую ссылку */}
      {guestProfile && (
        <GuestModalCurrent
          {...guestProfile}
          close={() => showGuestProfile(null)}
          projectData={panelData}
          fetchData={() => fetchData(sidebarDataType, panelData.id, panelData.title)}
        />
      )}

      {addGuestModal && (
        <CreateEditGuestModal
          close={() => showAddGuestModal(false)}
          dataType={sidebarDataType}
          projectData={panelData}
          fetchData={() => fetchData(sidebarDataType, panelData.id, panelData.title)}
        />
      )}

      <>
        <StyledAsideHeader>
          <StyledAsideTitle icon={iconMembers} iconsize={18}>
            {t('Project.members_roles')}
          </StyledAsideTitle>
          <IconButton
            icon={iconClose}
            size={27}
            onClick={() => {
              openSideMenu(null);
            }}
          />
        </StyledAsideHeader>
        <StyledAsideBody>
          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'people'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              <StyledAsideBackwardButton onClick={() => showProjectTree(true)}>
                Структура проекта
              </StyledAsideBackwardButton>

              {projectType.label && (panelData?.title || projectData?.title) && (
                <StyledAsideParentTask>
                  {projectType.label} : {panelData?.title || projectData?.title}
                </StyledAsideParentTask>
              )}

              {(sidebarDataType === 'project' || sidebarDataType === 'office') && taskId && projectType.textOpen && (
                <StyledAsideLink to={`/projects/${projectId}`}>{projectType.textOpen}</StyledAsideLink>
              )}

              {(sidebarDataType === 'task' || sidebarDataType === 'request' || sidebarDataType === 'rubric') &&
                panelData &&
                panelData.id !== +taskId &&
                projectType.textOpen && (
                  <StyledAsideLink to={`/projects/${projectId}/tasks/${panelData.id}`}>
                    {projectType.textOpen}
                  </StyledAsideLink>
                )}

              {isLoading && <Preloader />}

              {errorLoading && <div>{t('ErrorMessages.default')}</div>}

              {panelData && (
                <>
                  <StyledAsideSubtitle>{t('Project.created_by')}</StyledAsideSubtitle>
                  <AsideUserCard
                    userId={creator_id}
                    lastName={creator_last_name}
                    firstName={creator_first_name}
                    showUserProfile={showUserProfile}
                  />

                  {responsible_id && (
                    <>
                      <StyledAsideSubtitle>{t('Project.responsible')}</StyledAsideSubtitle>
                      <AsideUserCard
                        userId={responsible_id}
                        lastName={responsible_last_name}
                        firstName={responsible_first_name}
                        showUserProfile={showUserProfile}
                      />
                    </>
                  )}

                  {executor_id && (
                    <>
                      <StyledAsideSubtitle>{t('Project.executor')}</StyledAsideSubtitle>
                      <AsideUserCard
                        userId={executor_id}
                        lastName={executor_last_name}
                        firstName={executor_first_name}
                        showUserProfile={showUserProfile}
                      />
                    </>
                  )}

                  <StyledAsideParentTask>
                    {renderMembers(panelData.project_members || panelData.members)}
                  </StyledAsideParentTask>
                </>
              )}
            </>
          )}
        </StyledAsideBody>
      </>
    </>
  );
};

export default MembersSidePanel;
