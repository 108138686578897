import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Label } from '@shared/components';
import { StyledAuth, StyledAuthForm, StyledAuthInfo, StyledAuthLink, StyledAuthTitle } from '@components/form/styles';
import {
  StyledErrorText,
  StyledIconEye,
  StyledIconValid,
  StyledInput,
  StyledInputWrapper,
} from '@components/form/FormField/styles';
import eyeOpen from '@assets/images/icons/eye-visible.svg';
import eyeClose from '@assets/images/icons/eye-invisible.svg';
import iconValid from '@assets/images/icons/checkbox-green.svg';
import styled from 'styled-components';
import SnackbarContext from 'src/contexts/SnackbarContext';

const shemaRestorePassword = yup.object().shape({
  password: yup.string().required(),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
});

const PositionedValidIcon = styled(StyledIconValid)`
  right: 36px;
  bottom: 13px;
`;

const RestorePassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const {
    formState: { isValid, errors, dirtyFields },
    handleSubmit,
    register,
    trigger,
  } = useForm({
    resolver: yupResolver(shemaRestorePassword),
    mode: 'onChange',
    defaultValues: { password: '', passwordConfirm: '' },
  });

  const [isSending, setIsSending] = useState(false);
  const [passwordsVisible, setPasswordsVisible] = useState(false);

  const { showSnackbar } = useContext(SnackbarContext);

  const onSubmit = async (credentials) => {
    setIsSending(true);
    try {
      const response = await axios.put('/api/password_reset', {
        password: credentials.password,
        token,
      });
      if (response.status === 200) {
        navigate('/login');
        showSnackbar('Пароль успешно изменен', 'success');
      }
    } catch (e) {
      showSnackbar('Возникла ошибка при изменении пароля');
      setIsSending(false);
    }
  };
  return (
    <>
      <StyledAuth>
        <StyledAuthTitle>Восстановление</StyledAuthTitle>
        <StyledAuthInfo>Введите новый пароль</StyledAuthInfo>

        <StyledAuthInfo>
          Вспомнили данные аккаунта? <StyledAuthLink to="/login">Войти</StyledAuthLink>
        </StyledAuthInfo>

        <StyledAuthForm onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Label htmlFor="phone">Пароль</Label>
            <StyledInputWrapper>
              <StyledInput
                {...register('password')}
                onKeyUp={() => {
                  if (dirtyFields.passwordConfirm) trigger('passwordConfirm');
                }}
                type={passwordsVisible ? 'text' : 'password'}
                placeholder="Введите пароль"
                $error={errors?.password}
                autoComplete="off"
              />
              {dirtyFields.password && !errors.password && <PositionedValidIcon icon={iconValid} />}
              {false && <StyledErrorText>Введите пароль</StyledErrorText>}

              <StyledIconEye
                icon={passwordsVisible ? eyeOpen : eyeClose}
                size={18}
                onClick={() => setPasswordsVisible((v) => !v)}
              />
            </StyledInputWrapper>
          </div>
          <div>
            <Label htmlFor="phone">Подтвердите пароль</Label>
            <StyledInputWrapper>
              <StyledInput
                {...register('passwordConfirm')}
                type={passwordsVisible ? 'text' : 'password'}
                placeholder="Введите пароль еще раз"
                $error={errors?.passwordConfirm}
                autoComplete="off"
              />
              {dirtyFields.passwordConfirm && !errors.passwordConfirm && <PositionedValidIcon icon={iconValid} />}
              {errors?.passwordConfirm && <StyledErrorText>{errors?.passwordConfirm.message}</StyledErrorText>}

              <StyledIconEye
                icon={passwordsVisible ? eyeOpen : eyeClose}
                size={18}
                onClick={() => setPasswordsVisible((v) => !v)}
              />
            </StyledInputWrapper>
          </div>

          <Button width="100%" disabled={!isValid || isSending} name="submit">
            {isSending ? 'Отправка...' : 'Сменить пароль и войти'}
          </Button>
        </StyledAuthForm>
      </StyledAuth>
    </>
  );
};

export default RestorePassword;
