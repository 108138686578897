import { rtkQueryApi } from './rtkQueryApi';

export const controlApi = rtkQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsersRights: builder.query({
      query: (body) => ({ url: '/api/view_employee_rights', method: 'PATCH', body }),
      providesTags: ['UsersRights'],
    }),
    getUsersActivity: builder.query({
      query: (body) => ({ url: '/api/employee_activity', method: 'PATCH', body }),
      providesTags: ['UsersActivity'],
    }),
    getUsersEngagement: builder.query({
      query: (body) => ({ url: '/api/employee_engagement', method: 'PATCH', body }),
      providesTags: ['UsersEngagement'],
    }),
    getUserActivityDetails: builder.query({
      query: (body) => ({ url: '/api/activity_by_employee', method: 'PATCH', body }),
      providesTags: ['UserActivityDetails'],
    }),
    getUserEngagementDetails: builder.query({
      query: (userId) => `/api/engagement_by_employee/${userId}`,
      providesTags: ['UserEngagementDetails'],
    }),
  }),
  tagTypes: ['UsersRights', 'UsersActivity', 'UserActivityDetails', 'UserEngagementDetails'],
});

export const {
  useLazyGetUsersRightsQuery,
  useLazyGetUsersActivityQuery,
  useLazyGetUserActivityDetailsQuery,
  useLazyGetUsersEngagementQuery,
  useGetUserEngagementDetailsQuery,
} = controlApi;
