import { Button } from '@shared/components';
import { useState } from 'react';
import { UserActivityDetailsModal } from 'src/windows/control/UserActivityDetailsModal/UserActivityDetailsModal';

export function DetailsButton({ userId }) {
  const [activityDetailsModal, setActivityDetailsModal] = useState(false);

  return (
    <>
      <div>
        <Button color="outlined-green" onClick={() => setActivityDetailsModal(true)}>
          Подробнее
        </Button>
      </div>

      {activityDetailsModal && (
        <UserActivityDetailsModal userId={userId} onClose={() => setActivityDetailsModal(false)} />
      )}
    </>
  );
}
