import { styled } from 'styled-components';
import { Title } from '@shared/components';

export const Wrapper = styled.div`
  margin: 0 -24px;
  padding: 24px;
  background-color: ${({ theme }) => theme.light};
  display: grid;
  row-gap: 24px;
`;

export const StyledSubheader = styled.div`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate || '1fr 1fr 1fr 1fr'};
  align-items: center;
  gap: 24px;

  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding: 32px 0;
`;

export const StyledTitle = styled(Title)`
  padding: 0;
`;

export const StyledName = styled.div`
  font-weight: 500;
`;

export const StyledDepartment = styled.div`
  color: ${({ theme }) => theme.dark};
`;

export const StyledEmployeeCard = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  padding: 12px 24px;
  margin: 0 -24px;
  gap: 12px 24px;
  transition: all 0.3s;
  align-items: start;

  @media (max-width: 1250px) {
    grid-template-columns: 1fr;
    gap: 12px;
    align-items: center;
  }
`;

export const StyledEmployeeCardHeader = styled(StyledEmployeeCard)`
  color: ${({ theme }) => theme.dark};
  font-weight: 500;
  @media (max-width: 1250px) {
    display: none;
  }
`;

export const StyledQuantityLabel = styled.span`
  display: none;
  color: ${({ theme }) => theme.dark};
  font-weight: 500;

  @media (max-width: 1250px) {
    display: unset;
  }
`;

export const StyledQuantity = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1250px) {
    justify-content: space-between;
    padding: 0 10vw;
  }
`;

export const StyledOverdue = styled.div`
  color: ${({ theme }) => theme.warning};

  & ul {
    list-style: disc;
    padding-left: 14px;
  }
`;
