import moment from 'moment';
import { memo, useCallback, useContext, useEffect } from 'react';
import { Container } from '@shared/components';
import { useForm } from 'react-hook-form';
import SnackbarContext from 'src/contexts/SnackbarContext';

import { StyledPeriodLabel } from './styles';
import { StartDateSelect } from './StartDateSelect';
import { EndDateSelect } from './EndDateSelect';

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

export const PeriodSelects = memo(PeriodSelectsFC);

function PeriodSelectsFC({ getUserActivityDetails, userId }) {
  const { control, getValues } = useForm({
    defaultValues: {
      date_from: oneMonthAgo,
      date_to: new Date(),
    },
  });

  const { showSnackbar } = useContext(SnackbarContext);

  const onDateChange = useCallback(async () => {
    if (!userId) return;
    const values = getValues();
    if (!(values.date_from instanceof Date) || !(values.date_to instanceof Date)) return;

    const response = await getUserActivityDetails({
      employee_id: userId,
      date_from: moment(values.date_from).format('YYYY/M/D'),
      date_to: moment(values.date_to).format('YYYY/M/D'),
    });
    if ('error' in response) showSnackbar('Не удалось получить данные об активности');
  }, [getValues, showSnackbar]);

  useEffect(() => {
    onDateChange();
  }, [onDateChange]);

  return (
    <Container gap="20px" align="center">
      <StyledPeriodLabel>За период:</StyledPeriodLabel>

      <StartDateSelect control={control} onDateChange={onDateChange} />
      <EndDateSelect control={control} onDateChange={onDateChange} />
    </Container>
  );
}
