import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar } from '@shared/components/Avatar';
import 'swiper/css';
import 'swiper/css/pagination';

import iconFile from '@assets/images/icons/icon-file.svg';
import { IconButton } from '@shared/components';

import MessagePoll from '@components/chat/message/MessagePoll/MessagePoll';
import { getOldMessages, setColoredMsgId, setIsScrolledChatBottom } from 'src/redux/features/messagesSlice';

import { StyledPinnedMessage, StyledPinnedText } from './styles';

export const PinnedMessage = ({ message, projectData, swiperRef, updatePinnedMessageHeight }) => {
  const dispatch = useDispatch();

  const votedCount = useMemo(() => {
    if (
      typeof message?.props.poll?.all_vote_count !== 'number' ||
      typeof message?.props.poll.poll_results.refused_vote_count !== 'number'
    ) {
      return 0;
    }

    return message?.props.poll.all_vote_count + message?.props.poll.poll_results.refused_vote_count;
  }, [message?.props.poll]);

  const pinnedMessageRef = useRef(null);

  const goToMessage = useCallback(
    (messageId) => {
      if (!messageId) return;
      const foundMessage = document.getElementById(`messageId${messageId}`);
      if (foundMessage) foundMessage.scrollIntoView({ block: 'center' });
      else dispatch(getOldMessages({ chat_id: projectData.chat.id, old_message_id: messageId }));
      dispatch(setColoredMsgId(messageId));
      dispatch(setIsScrolledChatBottom(false));
    },
    [dispatch],
  );

  const goToMessageHandler = useCallback(() => {
    if (!message?.props?.id) return;

    goToMessage(message.props.id);
    if (swiperRef.current) {
      const currentIndex = swiperRef.current.activeIndex;
      const totalSlides = swiperRef.current.slides.length;

      if (typeof currentIndex !== 'number') swiperRef.current.slidePrev();
      const nextSlideIndex = currentIndex === 0 ? totalSlides - 1 : currentIndex - 1;
      swiperRef.current.slideTo(nextSlideIndex);
      updatePinnedMessageHeight(nextSlideIndex);
    }
  }, [goToMessage, message.props?.id]);

  return (
    <div
      ref={pinnedMessageRef}
      id={`styledPinnedMessage-${message.props.id}`}
      onClick={goToMessageHandler}
      style={{ maxHeight: '36vh', overflowY: 'auto' }}
    >
      <StyledPinnedMessage>
        <Avatar
          userId={message.props.employee_id}
          firstName={message.props.employee_first_name}
          lastName={message.props.employee_last_name}
        />
        {message.props.forwarded && (
          <>
            <IconButton icon={iconFile} size={16} style={{ marginRight: '8px' }} />
            Пересланное сообщение
          </>
        )}

        {message?.props.message_files?.length > 0 && (
          <>
            <IconButton icon={iconFile} size={16} style={{ marginRight: '8px' }} />
            Прикрепленные файлы ({message.props.message_files.length})
          </>
        )}
        <StyledPinnedText>{message.props.message}</StyledPinnedText>

        {message.props.poll && (
          <MessagePoll pollData={message.props.poll} votedCount={votedCount} message={message.props} />
        )}
      </StyledPinnedMessage>
    </div>
  );
};
