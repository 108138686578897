import CalendarForm from '@shared/components/CalendarForm/CalendarForm';
import { useWatch } from 'react-hook-form';

export function EndDateSelect({ control, onDateChange }) {
  const dateFrom = useWatch({ control, name: 'date_from' });

  return (
    <CalendarForm
      data={{
        name: 'date_to',
        placeholder: 'конец',
      }}
      control={control}
      onChange={onDateChange}
      minDate={dateFrom}
    />
  );
}
