import { useMemo } from 'react';

const projectTypeTranslations = {
  project: { label: 'Проект', open: 'Открыть проект' },
  office: { label: 'Виртуальный офис', open: 'Открыть виртуальный офис' },
  request: { label: 'Просьба', open: 'Открыть просьбу' },
  rubric: { label: 'Раздел', open: 'Открыть раздел' },
};

export function useProjectType({ projectData, sidebarDataType }) {
  const projectType = useMemo(() => {
    if (projectTypeTranslations[sidebarDataType]) {
      return {
        label: projectTypeTranslations[sidebarDataType].label,
        textOpen: projectTypeTranslations[sidebarDataType].open,
      };
    } else if (projectTypeTranslations[projectData?.type]) {
      return {
        label: projectTypeTranslations[projectData.type].label,
        textOpen: projectTypeTranslations[projectData.type].open,
      };
    }
    return { label: null, textOpen: null };
  }, [projectData?.type, sidebarDataType]);

  return projectType;
}
