import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isHistorySidebarOpen: false,
  historySidebarX: 0,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setHistorySidebarOpen: (state, { payload }) => {
      state.isHistorySidebarOpen = payload;
    },
    setHistorySidebarX: (state, { payload }) => {
      state.historySidebarX = payload;
    },
  },
});

export const { setHistorySidebarOpen, setHistorySidebarX } = commonSlice.actions;

export default commonSlice.reducer;
