import React, { useState } from 'react';
import ConfirmAction from '@components/warnings/ConfirmAction';
import { IconButton } from '@shared/components/IconButton/IconButton';
import iconEdit from '@assets/images/icons/edit.svg';
import iconDelete from '@assets/images/icons/delete.svg';
import iconDnd from '@assets/images/icons/dnd2.svg';

import {
  StyledIconDnd,
  StyledModalCheckboxWrapper,
  StyledModalGrid,
  StyledModalGridButtons,
  StyledModalGridCell,
  StyledModalGridLabel,
  StyledSeparator,
} from '@components/nav/AdditionalInfoModal/styles';
import { Checkbox } from '@shared/components';

import { useDeleteAdditionalFieldMutation } from 'src/redux/features/api/additionalInfo';

import { EditAdditionalFieldModal } from '../EditAdditionalFieldModal';
import { fieldTypes } from '../../lib';

// компонент списка групп контактов
export const FieldItem = ({ item, hasRightToEdit }) => {
  const [confirmDeleteModal, showConfirmDeleteModal] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);

  const [deleteField] = useDeleteAdditionalFieldMutation();

  const handleDelete = async () => {
    await deleteField({
      additional_info_id: item.id,
    });
    showConfirmDeleteModal(false);
  };

  return (
    <StyledModalGrid grid="3.5fr 1fr 1.5fr 100px">
      <StyledIconDnd icon={iconDnd} size={20} />
      <StyledModalGridCell>
        <StyledModalGridLabel>Название</StyledModalGridLabel>
        <p>{item.name}</p>
      </StyledModalGridCell>

      <StyledModalGridCell>
        <StyledModalGridLabel>Тип</StyledModalGridLabel>
        <p>{fieldTypes[item.type]}</p>
      </StyledModalGridCell>

      <StyledModalCheckboxWrapper>
        <Checkbox disabled checked={!!item.include} />
        <StyledModalGridLabel>Выводим в список</StyledModalGridLabel>
      </StyledModalCheckboxWrapper>

      <StyledModalGridButtons>
        {hasRightToEdit && (
          <>
            <IconButton size={18} icon={iconEdit} onClick={() => setEditModalVisible(true)} />
            <IconButton
              size={18}
              icon={iconDelete}
              onClick={() => showConfirmDeleteModal(true)}
              isDisabled={!item.deletable}
            />
          </>
        )}
      </StyledModalGridButtons>

      <StyledSeparator />

      {confirmDeleteModal && (
        <ConfirmAction
          actionText={'Вы уверены что хотите удалить данное поле?'}
          cancel={() => showConfirmDeleteModal(false)}
          confirm={handleDelete}
          confirmButtonText="Удалить"
        />
      )}

      {editModalVisible && <EditAdditionalFieldModal item={item} onClose={() => setEditModalVisible(false)} />}
    </StyledModalGrid>
  );
};
