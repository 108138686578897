import { memo } from 'react';
import { IconButton } from '@shared/components';
import iconPinList from '@assets/images/icons/pinlist.svg';
import { useMessageContext } from 'src/contexts/MessageContext';

import { PinnedMessagesList } from './PinnedMessagesList';
import PinnedMessageModal from './PinnedMessage/PinnedMessageModal';

export const PinnedMessages = memo(PinnedMessagesFC);

function PinnedMessagesFC({ hideHeader, pinnedMessagesList, projectData }) {
  const { pinnedMessagesModal, setPinnedMessagesModal } = useMessageContext();

  return (
    <>
      <PinnedMessagesList pinnedMessagesList={pinnedMessagesList} projectData={projectData} hideHeader={hideHeader} />

      <IconButton
        icon={iconPinList}
        size={32}
        style={{ alignSelf: 'end', marginLeft: 'auto' }}
        onClick={() => setPinnedMessagesModal(true)}
        title="Показать закрепленные сообщения"
      />

      {/* окно со списком закрепленных сообщений */}
      {pinnedMessagesModal && (
        <PinnedMessageModal pinnedMessagesList={pinnedMessagesList} onClose={() => setPinnedMessagesModal(false)} />
      )}
    </>
  );
}
