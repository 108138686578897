import { useContext, useEffect, useMemo, useState } from 'react';

import { Modal } from '@shared/components';

import useAuth from 'src/hooks/useAuth';

import { useEditAdditionalFieldsMutation, useGetAdditionalInfoListQuery } from 'src/redux/features/api/additionalInfo';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Placeholder } from '@shared/components/Placeholder';

import Preloader from '@components/preloaders/Preloader';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { CreateAdditionalFieldModal } from './ui/CreateAdditionalFieldModal';
import { StyledModalGrid, StyledModalGridCell, StyledModalGridHeader } from './styles';
import { FieldItem } from './ui/ListItem';

export const AdditionalInfoModal = ({ onClose }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { showSnackbar } = useContext(SnackbarContext);

  const { data, isFetching } = useGetAdditionalInfoListQuery();
  const [editFields] = useEditAdditionalFieldsMutation();

  const [fieldsList, setFieldsList] = useState([]);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const { isUserRightful, rightTypes } = useAuth();
  const hasRightToEdit = useMemo(() => isUserRightful(rightTypes.contacts_edit), [isUserRightful, rightTypes]);

  const headerButtons = [{ name: 'Добавить', action: () => setAddModalVisible(true) }];

  const handlerCloseWindow = () => onClose();

  const getUpdatedListData = (fields) => {
    return fields.map((field, index) => ({
      row: {
        order: index + 1,
      },
      additional_info_id: field.id,
    }));
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFieldsList((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const orderedFieldsList = arrayMove(items, oldIndex, newIndex);

        editFields({
          rows: [...getUpdatedListData(orderedFieldsList)],
        }).catch(() => {
          showSnackbar('Произошла ошибка во время сортировки', 'error');
        });

        return orderedFieldsList;
      });
    }
  }

  useEffect(() => {
    if (data) setFieldsList(data?.companyGroups);
  }, [data]);

  return (
    <>
      {!addModalVisible && (
        <Modal
          modalSize="960px"
          title={'Дополнительная информация в контакты'}
          onClose={handlerCloseWindow}
          headerButtons={hasRightToEdit && headerButtons}
          withoutButtons
        >
          {
            <>
              <StyledModalGrid grid="3.5fr 1fr 1.5fr 100px">
                <StyledModalGridHeader>
                  <StyledModalGridCell>Название</StyledModalGridCell>
                  <StyledModalGridCell>Тип</StyledModalGridCell>
                  <StyledModalGridCell>Выводим в список</StyledModalGridCell>
                  {hasRightToEdit && <StyledModalGridCell align="right">Действия</StyledModalGridCell>}
                </StyledModalGridHeader>
              </StyledModalGrid>
              {!data?.companyGroups?.length && !isFetching ? (
                <Placeholder heigth={'60px'} textSize={2} />
              ) : isFetching ? (
                <Preloader />
              ) : (
                <div>
                  <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={fieldsList} strategy={verticalListSortingStrategy}>
                      {fieldsList?.map((item) => (
                        <SortableItem key={item.id} item={item}>
                          <FieldItem key={item.id} item={item} hasRightToEdit={hasRightToEdit} />
                        </SortableItem>
                      ))}
                    </SortableContext>
                  </DndContext>
                </div>
              )}
            </>
          }
        </Modal>
      )}

      {addModalVisible && (
        <CreateAdditionalFieldModal
          order={fieldsList.length + 1}
          close={() => {
            setAddModalVisible(false);
          }}
        />
      )}
    </>
  );
};

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
}
