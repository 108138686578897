import { StyledMockAvatar } from '@shared/components/Avatar/styles';
import { files_url } from 'src/settings/base-url';
import { components } from 'react-select';

import { Avatar } from '@shared/components/Avatar';
import { formatDateforPicker, formatReactSelectTagsOptions } from 'src/utilize/helper-functions';

import { Wrapper } from './styles';

export const getOptions = ({ users, token }) => {
  if (!users) return [];
  const options = users.map((user) => ({
    label: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
    value: {
      id: user.id,
      name: `${user.last_name ? `${user.last_name} ${user.first_name}` : user.first_name}`,
      pic: !!user.avatar
        ? `${files_url}/employees/avatar/${user.id}/small_${user.avatar}?token=${token}`
        : StyledMockAvatar,
    },
  }));

  return options;
};

export const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Wrapper align={'center'}>
        {props.data?.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.Option>
  );
};

export const CustomValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <Wrapper align={'center'}>
        {props.data.value?.id && <Avatar size={24} userId={props.data.value.id} />} {children}
      </Wrapper>
    </components.SingleValue>
  );
};

// получить предыдущие тэги
const getPrevTags = (main, own) => {
  const mainAndOwn = [...main, ...own];
  // найти те items в списке общих, которые были присвоены ранее
  let defaultMulty = mainAndOwn.filter((el) =>
    own.some((el2) => el2.id === el.id && el2?.name === el?.name && el2?.color === el?.color),
  );
  return formatReactSelectTagsOptions(defaultMulty);
};

export function getDefValues(prevData, token) {
  const {
    date_start,
    date_finish,
    responsible_id,
    //executor_id,
    project_tags,
    project_members,
    tags,
    title,
    members,
    chatless,
  } = prevData || {};

  // эти поля долдны совпадать с настройками в пропсах формы
  // в value даем id руководителя, которые ждет от нас сервер
  return {
    // нельзя ставить null обычному input ругается консоль
    title_project: title || '',
    description: '',
    // участники
    attendee: project_members ? getOptions({ users: project_members, token }) : [],
    responsible: responsible_id
      ? getOptions({ users: members, token }).filter((member) => member.value.id === responsible_id)[0]
      : null,

    // // исполнитель
    // executor: executor_id
    //   ? getOptions({ users: members, token: auth.token }).filter((member) => member.value.id === executor_id)[0]
    //   : null,
    tags: project_tags ? getPrevTags(tags, project_tags) : [],
    deadline_start: formatDateforPicker(date_start),
    deadline_end: formatDateforPicker(date_finish),
    chatless: Boolean(chatless) || false,
  };
}
