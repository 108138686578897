import styled from 'styled-components';

export const StyledModalContainer = styled.div`
  & a {
    display: block;
    margin: 10px 0;
    transition: 0.3s;

    &:hover {
      color: #1890ff;
    }
  }
`;

export const StyledProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledRequestsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 84px;
  gap: 20px;
  margin-top: 10px;

  & a {
    margin: 0;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledDeadline = styled.span`
  ${({ $isOverdue, theme }) => $isOverdue && `font-weight: 500; color:${theme.warning}`};
`;
