import axios from 'axios';
import { convertToRaw } from 'draft-js';
import moment from 'moment';
import { useCallback, useContext } from 'react';
import SnackbarContext from 'src/contexts/SnackbarContext';
import { useCreateSurveyMutation } from 'src/redux/features/api/surveys';
import { randomSequence } from 'src/utilize/helper-functions';

export function useAddSurvey() {
  const { showSnackbar } = useContext(SnackbarContext);

  const [createSurvey] = useCreateSurveyMutation();

  const handleSurveySubmit = useCallback(
    async (formValues) => {
      if (!formValues) return;

      let hasImagesToUpload = false;
      const poll_answers = formValues.poll_answers.map((answer) => {
        if (answer.file instanceof File) {
          answer.div_id = randomSequence();
          hasImagesToUpload = true;
        }
        return answer;
      });

      if (hasImagesToUpload) {
        const promises = [];
        let error = false;
        poll_answers.forEach((answer) => {
          if (answer.file && answer.div_id) {
            const formData = new FormData();

            formData.append('file', answer.file, answer.file.name);
            formData.append('div_id', answer.div_id);
            promises.push(axios.post('/api/poll_answer_file/add', formData).catch(() => (error = true)));
          }
        });

        await Promise.all(promises);
        if (error) {
          showSnackbar('Не удалось загрузить картинки');
          return;
        }
      }

      const currentContent = formValues.survey_text?.getCurrentContent();
      const rawEditorContent = convertToRaw(currentContent);
      const plainText = currentContent.getPlainText();

      const data = {
        row: {
          title: formValues.title,
          survey_text: plainText,
          editor_state: JSON.stringify(rawEditorContent),
          anonymous: formValues.anonymous,
          multianswer: formValues.multianswer.value,
          date_finish: formValues.date_finish ? moment.utc(formValues.date_finish).format('YYYY-MM-DD-LT') : null,
        },
        survey_answers: poll_answers.map((item) => {
          const pollOption = { answer: item.value };
          if (item.div_id) pollOption.div_id = item.div_id;
          return pollOption;
        }),
        survey_members: formValues.poll_members?.map((item) => item.value.id),
      };

      const response = await createSurvey(data);
      if ('error' in response) {
        throw new Error('Не удалось создать опрос');
      }
    },
    [createSurvey],
  );

  const getPollCreateData = useCallback(async () => {
    const response = await axios.get(`/api/survey_create`);
    if (response.data.dataForSurveyCreation) {
      return { data: response.data.dataForSurveyCreation };
    }
  }, []);

  return { handleSurveySubmit, getPollCreateData };
}
