import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { PinnedMessage } from './PinnedMessage/PinnedMessage';
import { StyledPinnedContainer } from './PinnedMessage/styles';

export const PinnedMessagesList = memo(PinnedMessagesListFC);

function PinnedMessagesListFC({ pinnedMessagesList, projectData, hideHeader }) {
  const swiperRef = useRef(null);
  const [pollMessageHeight, setPollMessageHeight] = useState(null);

  const pinnedMessagesContainerRef = useRef(null);

  const updatePinnedMessageHeight = useCallback(
    (slideIndex) => {
      if (typeof slideIndex !== 'number') return;
      const currentPinnedMessage = pinnedMessagesList[slideIndex];
      // если есть опрос, то расширить закрепленное сообщение под высоту опроса (но не более 36vh)
      if (currentPinnedMessage?.props?.poll) {
        const currentSlide = pinnedMessagesContainerRef.current.querySelector(
          `#styledPinnedMessage-${currentPinnedMessage.props.id}`,
        );
        if (!currentSlide) {
          setPollMessageHeight(null);
          return;
        }

        setPollMessageHeight(currentSlide.clientHeight <= 50 ? null : currentSlide.clientHeight);
      } else setPollMessageHeight(null);
    },
    [pinnedMessagesList],
  );

  // первоначальный вызов функции расширения высоты закрпеленного сообщения, если есть опрос
  useEffect(() => {
    if (!swiperRef.current || typeof swiperRef.current.activeIndex !== 'number') return;

    updatePinnedMessageHeight(swiperRef.current.activeIndex);
  }, [updatePinnedMessageHeight]);

  return (
    <StyledPinnedContainer $noBorder={hideHeader} $height={pollMessageHeight} ref={pinnedMessagesContainerRef}>
      <Swiper
        slidesPerView={1}
        direction="vertical"
        initialSlide={pinnedMessagesList.length - 1}
        mousewheel={false}
        allowTouchMove={false}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        height={pollMessageHeight || 50}
      >
        {pinnedMessagesList.map((message, i) => {
          return (
            <SwiperSlide key={Math.random() + i}>
              <PinnedMessage
                message={message}
                projectData={projectData}
                swiperRef={swiperRef}
                updatePinnedMessageHeight={updatePinnedMessageHeight}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </StyledPinnedContainer>
  );
}
