import CalendarForm from '@shared/components/CalendarForm/CalendarForm';
import { useWatch } from 'react-hook-form';

export function StartDateSelect({ control, onDateChange }) {
  const dateFrom = useWatch({ control, name: 'date_to' });

  return (
    <CalendarForm
      data={{
        name: 'date_from',
        placeholder: 'начало',
      }}
      control={control}
      onChange={onDateChange}
      maxDate={dateFrom}
    />
  );
}
