import { SingleSelect } from '@shared/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const DepartmentSelect = ({ control }) => {
  const departments = useSelector((state) => state.users.departments);

  const selectOptions = useMemo(() => {
    if (!Array.isArray(departments)) return [];

    return departments
      .map((department) => ({ value: department.id, label: department.title }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [departments]);

  return (
    <Controller
      name="department"
      control={control}
      render={({ field }) => (
        <SingleSelect {...field} ref={null} placeholder="Отдел" options={selectOptions} isClearable />
      )}
    />
  );
};

export default DepartmentSelect;
