import PropTypes from 'prop-types';
import { StyledAsideBody, StyledAsideHeader, StyledAsideTitle } from '@components/chat/side-menu/styles';
import iconStructure from '@assets/images/icons/icon-structure.svg';
import iconSort from '@assets/images/icons/sort-icon.svg';

import iconClose from '@assets/images/icons/close.svg';
import { IconButton } from '@shared/components';

import { useDispatch } from 'react-redux';

import { setSortingEnabled } from 'src/redux/features/projectsSlice';

import { useSelector } from 'react-redux';

import ProjectTree from './ProjectTree';

StructureSidePanel.propTypes = {
  sideMenu: PropTypes.string,
  openSideMenu: PropTypes.func.isRequired,
};

function StructureSidePanel({ sideMenu, openSideMenu }) {
  const dispatch = useDispatch();
  const { isSortingEnabled } = useSelector((state) => state.projects);

  const toggleSorting = () => dispatch(setSortingEnabled(!isSortingEnabled));

  return (
    <>
      <StyledAsideHeader>
        <StyledAsideTitle icon={iconStructure} iconsize={20}>
          Структура
        </StyledAsideTitle>

        <IconButton icon={iconSort} size={27} onClick={toggleSorting} $active={isSortingEnabled} />

        <IconButton
          icon={iconClose}
          size={27}
          onClick={() => {
            openSideMenu(null);
          }}
        />
      </StyledAsideHeader>
      <StyledAsideBody>
        {/* обновлять боковое меню Структура, если sideMenu равно "db" */}
        <ProjectTree update={sideMenu === 'db'} openSideMenu={openSideMenu} />
      </StyledAsideBody>
    </>
  );
}

export default StructureSidePanel;
