import { SingleSelect } from '@shared/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useGetPortalRightsListQuery } from 'src/redux/features/api/employees';
import { rightsRussianLabels } from 'src/utilize/rights-russian-labels';

export function RightsSelect({ control }) {
  const { data } = useGetPortalRightsListQuery();

  const selectOptions = useMemo(() => {
    if (!Array.isArray(data?.rights)) return [];
    return data?.rights
      .map((right) => ({ value: right, label: rightsRussianLabels[right] || right }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  return (
    <>
      <Controller
        name="right"
        control={control}
        render={({ field }) => <SingleSelect {...field} placeholder="Права" options={selectOptions} isClearable />}
      />
    </>
  );
}
