import React from 'react';
import { Modal } from '@shared/components';
import MessageContainer from '@components/chat/message/MessageContainer';

const PinnedMessageModal = ({ pinnedMessagesList, onClose }) => {
  return (
    <Modal withoutButtons onClose={onClose} title={'Закрепленные сообщения'}>
      {pinnedMessagesList.length > 0 ? (
        pinnedMessagesList.map((message) => (
          <MessageContainer
            key={message.props.id}
            {...message.props}
            goals={message.props.chat_goal_results}
            projectData={message.props.projectData}
            pinnedMessageType={false}
            style={{
              padding: '8px',
            }}
            isInModal={true}
          />
        ))
      ) : (
        <p>Нет закрепленных сообщений</p>
      )}
    </Modal>
  );
};

export default PinnedMessageModal;
