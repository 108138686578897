import { useEffect, useState, useRef, memo, Fragment, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledAsideForwardButton,
  StyledIcon,
  StyledIconDnd,
  StyledProjectTree,
  StyledProjectTreeItem,
  StyledProjectTreeTitle,
} from '@components/chat/side-menu/styles';
import iconDnd from '@assets/images/icons/dnd2.svg';

import iconDone from '@assets/images/icons/check-green.svg';
import iconLink from '@assets/images/icons/icon-new-window.svg';
import iconArrow from '@assets/images/icons/arrow.svg';

import { getProjectStructure, updateProjectStructureLocally } from 'src/redux/features/projectsSlice';
import { useProjectStructureContext } from 'src/contexts/ProjectStructureContext';
import Preloader from 'src/components/preloaders/Preloader';
import { arraysEqual } from 'src/utilize/helper-functions';

import { CSS } from '@dnd-kit/utilities';

import { DndContext, KeyboardSensor, PointerSensor, pointerWithin, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { server_url } from 'src/settings/base-url';
import axios from 'axios';

import SnackbarContext from 'src/contexts/SnackbarContext';

import { useMessageContext } from '../../../contexts/MessageContext';

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition, over } = useSortable({
    id: props.item.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    width: '100%',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {props.item.canSorting && <StyledIconDnd icon={iconDnd} {...listeners} />} {props.children}
    </div>
  );
}

// используется рекурсивно для рендера ветвей дерева
// контейнер используется чтобы все под-компоненты лишний раз не обновлялись при
// обновлении react контекста useProjectStructure
const TaskTreeItemContainer = (props) => {
  const { selectedTask, setSelectedTask } = useProjectStructureContext();

  return <MemoizedTaskTreeItem {...props} selectedTask={selectedTask} setSelectedTask={setSelectedTask} />;
};

// обновлять компонент MemoizedTaskTreeItem, если:
// 1) id выбранной задачи не соответствовала этому компоненту и теперь соответствует
// 2) id выбранной задачи соответствовала этому компоненту и теперь не соответствует
// 3) массив подзадач обновился
const memoSettings = (prevProps, nextProps) => {
  if (
    (!prevProps.selectedTask || prevProps.selectedTask !== prevProps.id) &&
    nextProps.selectedTask &&
    nextProps.selectedTask === nextProps.id
  ) {
    return false;
  } else if (prevProps.selectedTask === prevProps.id && nextProps.selectedTask !== nextProps.id) {
    return false;
  } else if (arraysEqual(prevProps.tasks, nextProps.tasks)) return true;
};

const TaskTreeItem = ({
  title,
  tasks,
  status,
  id,
  type,
  projectId,
  toggleParent,
  selectedTask,
  showRelevantTaskInfo,
  openSideMenu,
}) => {
  const [subtasks, toggleSubtasks] = useState(() => {
    // загружаем состояние из localStorage при инициализации
    const savedOpenTasks = JSON.parse(localStorage.getItem('openTasks')) || {};
    return !!savedOpenTasks[id];
  });

  const { isSortingEnabled } = useSelector((state) => state.projects);

  const paragraph = useRef();
  const { taskId } = useParams();

  // если в поле ввода остался текст, файлы, цитирование и тд, сбрасываем их при переходе в другую задачу
  const { resetEditor } = useMessageContext();

  // если есть подзадачи, то показать стрелку рядом с этой задачей
  useEffect(() => {
    if (tasks.length) {
      paragraph.current.dataset.spoiler = true;
    }
  }, [tasks]);

  const toggleChildren = (status) => {
    toggleSubtasks(status);
    if (toggleParent) toggleParent(status);
  };

  // сохранение состояния задач в localStorage при изменении
  useEffect(() => {
    const savedOpenTasks = JSON.parse(localStorage.getItem('openTasks')) || {};
    savedOpenTasks[id] = subtasks;
    localStorage.setItem('openTasks', JSON.stringify(savedOpenTasks));
  }, [subtasks, id]);

  // при первоначальной загрузке данных, если id открытой задачи соответствует элементу в дереве,
  // то раскрыть всю ветку задач в сайдбаре
  useEffect(() => {
    if (+taskId === id) {
      if (toggleParent) {
        toggleParent(true);
      }
    }
  }, [taskId, id, toggleParent]);

  // для определения одиночного и двойного клика
  const pendingClick = useRef();
  const clicked = useRef(0);

  const navigate = useNavigate();

  const onSingleClick = () => {
    clicked.current++;
    if (clicked.current >= 2) {
      if (showRelevantTaskInfo) {
        showRelevantTaskInfo(type, id, title);
        resetEditor && resetEditor.current();
      }
      clearTimeout(pendingClick.current);
      clicked.current = 0;
      return;
    }

    if (tasks.length) {
      pendingClick.current = setTimeout(() => {
        toggleSubtasks((subtasks) => !subtasks);
        clicked.current = 0;
      }, 250); // Задержка в 250 мс для отличия одиночного клика от двойного
    }
  };

  const onDoubleClick = () => {
    clearTimeout(pendingClick.current);
    clicked.current = 0;

    if (!showRelevantTaskInfo) {
      navigate(`/projects/${projectId}/tasks/${id}`);
      resetEditor && resetEditor.current();
      if (window.innerWidth < 1220) openSideMenu(null);
    }
  };

  return (
    <StyledProjectTreeItem $active={subtasks} icon={iconArrow}>
      <StyledProjectTreeTitle
        ref={paragraph}
        onClick={onSingleClick}
        onDoubleClick={onDoubleClick}
        style={{
          backgroundColor: selectedTask === id ? '#BAE7FF' : '',
        }}
      >
        {title}
        {/* показывать кнопку перехода на задачу, только если мы уже не находимся в данной задаче */}
        {(!taskId || id !== +taskId) && (
          <Link
            to={`/projects/${projectId}/tasks/${id}`}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <StyledIcon iconhover={iconLink} icondone={iconDone} $done={status === 'finished'} />
          </Link>
        )}
      </StyledProjectTreeTitle>

      <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
        <ul
          style={{
            transition: 'max-height 0.3s ease-in-out',
            maxHeight: subtasks ? '5000px' : '0',
            overflow: subtasks ? 'auto' : 'hidden',
            pointerEvents: subtasks ? 'auto' : 'none',
          }}
        >
          {tasks.map((task, i) =>
            isSortingEnabled && task.canSorting ? (
              <SortableItem key={task.id} item={task}>
                <TaskTreeItemContainer
                  {...task}
                  projectId={projectId}
                  toggleParent={toggleChildren}
                  showRelevantTaskInfo={showRelevantTaskInfo}
                  openSideMenu={openSideMenu}
                />
              </SortableItem>
            ) : (
              <Fragment key={task.id}>
                <TaskTreeItemContainer
                  {...task}
                  projectId={projectId}
                  toggleParent={toggleChildren}
                  showRelevantTaskInfo={showRelevantTaskInfo}
                  openSideMenu={openSideMenu}
                />
              </Fragment>
            ),
          )}
        </ul>
      </SortableContext>
    </StyledProjectTreeItem>
  );
};

// используется React memo, чтобы все ветви дерева не рендерились в лишний раз,
// чтобы обновлялась только выбранная ветвь
const MemoizedTaskTreeItem = memo(TaskTreeItem, memoSettings);

// ProjectTree используется в нескольких местах:
// в боковой панели StructureSidePanel - без возможности детализации выбранной задачи
// во всех остальных боковых панелях -  с возможностью отображения детализации по выбранной задаче
const ProjectTree = ({ update, showRelevantTaskInfo, showProjectTree, openSideMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resetEditor } = useMessageContext();
  const { selectedProject, setSelectedTask } = useProjectStructureContext();
  const { projectStructure, isLoadingProjectStructure, projectStructureError, isSortingEnabled } = useSelector(
    (state) => state.projects,
  );
  const { projectId, taskId } = useParams();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const { showSnackbar } = useContext(SnackbarContext);

  // получение данных по структуре проекта из сервера
  useEffect(() => {
    if (update && projectId) {
      dispatch(getProjectStructure({ projectId, taskId }));
    }
  }, [update, projectId, taskId, dispatch]);

  const [isProjectOpen, setIsProjectOpen] = useState(true);

  const taskSorting = (body) => axios.patch(`${server_url}/api/task_sorting`, body);

  const findTaskAndParent = (tasks, taskId, parent = null) => {
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].id === taskId) {
        return { tasks, index: i, parent, data: tasks[i] };
      }

      if (tasks[i].tasks && tasks[i].tasks.length > 0) {
        const result = findTaskAndParent(tasks[i].tasks, taskId, tasks[i]);
        if (result) return result;
      }
    }
    return null;
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      const activeTask = findTaskAndParent(projectStructure.tasks, active.id);
      const overTask = findTaskAndParent(projectStructure.tasks, over.id);

      if (activeTask && overTask && activeTask.tasks === overTask.tasks) {
        // Создаем новый порядок задач
        const newTasks = [...activeTask.tasks];
        const newOrder = arrayMove(newTasks, activeTask.index, overTask.index);

        const newProjectStructure = { ...projectStructure };

        // Находим и обновляем нужный уровень задач
        if (activeTask.parent) {
          const updateParentTasks = (tasks) => {
            return tasks.map((task) => {
              if (task.id === activeTask.parent.id) {
                return { ...task, tasks: newOrder };
              }

              if (task.tasks) {
                return { ...task, tasks: updateParentTasks(task.tasks) };
              }

              return task;
            });
          };

          newProjectStructure.tasks = updateParentTasks(newProjectStructure.tasks);
        } else {
          newProjectStructure.tasks = newOrder;
        }

        // Обновляем состояние в Redux
        dispatch(updateProjectStructureLocally(newProjectStructure));

        // Функция для формирования данных для отправки в нужном порядке
        const getPayloadData = () => {
          const activeTaskIndex = newOrder.findIndex((task) => task.id === activeTask.data.id);
          const prevTaskOrder = newOrder[activeTaskIndex - 1]?.order;

          return prevTaskOrder;
        };

        // Отправляем запрос на сервер
        taskSorting({
          task_id: activeTask.data.id,
          order: getPayloadData(),
        }).catch((e) => {
          // В случае ошибки возвращаем предыдущее состояние через dispatch
          dispatch(getProjectStructure({ projectId, taskId }));
          showSnackbar('Произошла ошибка во время сортировки', 'error');
        });
      }
    }
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        {/* показывать кнопку открытия текущей задачи, если это предусмотрено текущей боковой панелью  */}
        {showRelevantTaskInfo && (
          <StyledAsideForwardButton onClick={() => showProjectTree(false)}>Закрыть структуру</StyledAsideForwardButton>
        )}
        <StyledProjectTree data-spoilers>
          {isLoadingProjectStructure && <Preloader />}

          {projectStructureError && <div>{projectStructureError}</div>}

          {projectStructure && (
            <StyledProjectTreeItem $active={isProjectOpen} icon={iconArrow}>
              <StyledProjectTreeTitle
                style={{
                  backgroundColor: selectedProject === +projectId && !taskId ? '#BAE7FF' : '',
                }}
                data-spoiler
                onDoubleClick={() => {
                  if (showRelevantTaskInfo) {
                    showRelevantTaskInfo(projectStructure.type, projectStructure.id, projectStructure.title);
                    resetEditor && resetEditor.current();
                  } else {
                    setSelectedTask(null);
                    navigate(`/projects/${projectId}`);
                    resetEditor && resetEditor.current();
                    if (window.innerWidth < 1220) openSideMenu(null);
                  }
                }}
                onClick={() => {
                  setIsProjectOpen((prev) => !prev);
                }}
              >
                {projectStructure.title}
                {/* показывать кнопку перехода на корень проекта, только если мы находимся не в корне проекта (т.е. в задаче) */}
                {taskId ? (
                  <Link
                    to={`/projects/${projectStructure.id}`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <StyledIcon
                      iconhover={iconLink}
                      icondone={iconDone}
                      $done={projectStructure?.status === 'finished'}
                      onClick={() => {
                        setSelectedTask(null);
                        resetEditor && resetEditor.current();
                      }}
                    />
                  </Link>
                ) : (
                  <StyledIcon icondone={iconDone} $done={projectStructure?.status === 'finished'} />
                )}
              </StyledProjectTreeTitle>

              <ul
                style={{
                  transition: 'max-height 0.3s ease-in-out',
                  maxHeight: isProjectOpen ? '5000px' : '0',
                  overflow: isProjectOpen ? 'auto' : 'hidden',
                  pointerEvents: isProjectOpen ? 'auto' : 'none',
                  minHeight: '90vh',
                }}
              >
                <SortableContext items={projectStructure.tasks} strategy={verticalListSortingStrategy}>
                  {projectStructure.tasks.length > 0 && (
                    <>
                      {projectStructure.tasks.map((task, i) =>
                        isSortingEnabled && task.canSorting ? (
                          <SortableItem key={task.id} item={task}>
                            <TaskTreeItemContainer
                              {...task}
                              projectId={projectStructure.id}
                              showRelevantTaskInfo={showRelevantTaskInfo}
                              openSideMenu={openSideMenu}
                            />
                          </SortableItem>
                        ) : (
                          <Fragment key={task.id}>
                            <TaskTreeItemContainer
                              {...task}
                              projectId={projectStructure.id}
                              showRelevantTaskInfo={showRelevantTaskInfo}
                              openSideMenu={openSideMenu}
                            />
                          </Fragment>
                        ),
                      )}
                    </>
                  )}
                </SortableContext>
              </ul>
            </StyledProjectTreeItem>
          )}
        </StyledProjectTree>
      </DndContext>
    </>
  );
};

export default ProjectTree;
