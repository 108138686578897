import { Container, Modal, Text } from '@shared/components';
import { Avatar } from '@shared/components/Avatar';
import { useLazyGetUserActivityDetailsQuery } from 'src/redux/features/api/control';
// import { LineChart } from 'chartist';

// import 'chartist/dist/index.css';
// import './styles.css';

import Preloader from '@components/preloaders/Preloader';

import { PeriodSelects } from './PeriodSelects';

export function UserActivityDetailsModal({ onClose, userId, userName }) {
  // useEffect(() => {
  //   const chart = new LineChart(
  //     '#chart',
  //     {
  //       labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  //       series: [[23, 45, 12, 8, 67, 32, 19]],
  //     },
  //     {
  //       low: 0,
  //       showPoint: false,
  //       axisX: {
  //         labelInterpolationFnc: (value, index) => (index % 2 === 0 ? value : null),
  //       },
  //     },
  //   );
  //   console.log('CHART: ', chart);
  // }, []);

  const [getUserActivityDetails, { data, isFetching }] = useLazyGetUserActivityDetailsQuery();

  return (
    <Modal title="Активность сотрудника" withoutButtons onClose={onClose}>
      <Container align="center">
        <Avatar userId={userId} />
        <Text as="span" size={3}>
          {`${data?.activityByEmployee.first_name || ''} ${data?.activityByEmployee.last_name || ''}`}
        </Text>
      </Container>
      <PeriodSelects userId={userId} getUserActivityDetails={getUserActivityDetails} />
      {/* <div id="chart" className="ct-golden-section"></div> */}
      <div>
        {isFetching && <Preloader />}
        {data?.activityByEmployee && (
          <div>
            <Container justify="space-between">
              <Text size={3} as="span">
                Общее число действий: <span>{data.activityByEmployee.total_actions}</span>
              </Text>
              <Text size={3} as="span">
                Активных дней: <span>{data.activityByEmployee.active_days}</span>
              </Text>
            </Container>
            <br />
            <Text size={2} as="div">
              Просмотр проектов:{' '}
              <span>
                {data.activityByEmployee.action_type?.find((action) => action.type === 'view_project')?.action_count ||
                  0}
              </span>
            </Text>
            <Text size={2} as="div">
              Просмотр виртуальных офисов:{' '}
              <span>
                {data.activityByEmployee.action_type?.find((action) => action.type === 'view_office')?.action_count ||
                  0}
              </span>
            </Text>
            <br />
            <Text size={2} as="div">
              Создано просьб:{' '}
              <span>
                {data.activityByEmployee.action_type?.find((action) => action.type === 'create_request')
                  ?.action_count || 0}
              </span>
            </Text>
            <Text size={2} as="div">
              Просмотр просьб:{' '}
              <span>
                {data.activityByEmployee.action_type?.find((action) => action.type === 'view_request')?.action_count ||
                  0}
              </span>
            </Text>
            <br />
            <Text size={2} as="div">
              Создано разделов:{' '}
              <span>
                {data.activityByEmployee.action_type?.find((action) => action.type === 'create_rubric')?.action_count ||
                  0}
              </span>
            </Text>
            <Text size={2} as="div">
              Просмотр разделов:{' '}
              <span>
                {data.activityByEmployee.action_type?.find((action) => action.type === 'view_rubric')?.action_count ||
                  0}
              </span>
            </Text>
          </div>
        )}
      </div>
    </Modal>
  );
}
