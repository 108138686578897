import { ButtonWithIcon, Container, Title } from '@shared/components';
import { useMemo, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import iconAdd from '@assets/images/icons/plus.svg';
import { useGetSurveysQuery } from 'src/redux/features/api/surveys';
import Preloader from '@components/preloaders/Preloader';
import LocalDateTime from '@components/shared/LocalDateTime';
import { useAddSurvey } from 'src/hooks/useAddSurvey';
import { Link } from 'react-router-dom';
import CreatePollModal from 'src/windows/polls/CreatePollModal';

import { StyledProjectList } from '../Projects/project-list/ProjectList';

import { StyledPost, StyledPostDate, StyledPostTitle, StyledSurveysList } from './styles';

export function Surveys() {
  const auth = useAuth();

  const canCreateSurvey = useMemo(() => auth?.isUserRightful(auth.rightTypes.survey_create), [auth]);

  const [createSurveyModal, setCreateSurveyModal] = useState(false);

  const { data, isFetching } = useGetSurveysQuery();

  const { handleSurveySubmit, getPollCreateData } = useAddSurvey();

  return (
    <>
      <main>
        <StyledProjectList>
          <Container align={'center'} justify={'space-between'}>
            <Title>Опросы</Title>
            {canCreateSurvey && (
              <ButtonWithIcon
                color="green"
                position="left"
                icon={iconAdd}
                size={20}
                onClick={() => setCreateSurveyModal(true)}
              >
                Добавить опрос
              </ButtonWithIcon>
            )}
          </Container>
          <StyledSurveysList>
            {isFetching && <Preloader />}
            {!isFetching &&
              data?.surveys?.map((listItem) => {
                return (
                  <StyledPost key={listItem.id}>
                    <StyledPostDate>
                      <LocalDateTime dateTime={listItem.date_created} />
                    </StyledPostDate>
                    <Link to={`/surveys/${listItem.id}`}>
                      <StyledPostTitle>{listItem.title}</StyledPostTitle>
                    </Link>
                  </StyledPost>
                );
              })}
          </StyledSurveysList>
        </StyledProjectList>
      </main>
      {createSurveyModal && (
        <CreatePollModal
          submitRequest={handleSurveySubmit}
          getPollCreateDataRequest={getPollCreateData}
          close={() => setCreateSurveyModal(false)}
          isSurvey
        />
      )}
    </>
  );
}
