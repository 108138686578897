import styled from 'styled-components';

export const StyledChatWrapper = styled.main`
  padding-bottom: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
`;

export const StyledChat = styled.section`
  background-color: ${({ theme }) => theme.borderSecondary};
  height: 100%;
`;

export const StyledChatContainer = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 24px;
  height: 100%;

  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const StyledChatInner = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
`;

export const StyledChatPage = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: max-content 1fr max-content;
`;
