import { StyledLayout } from '@shared/components/Layout/styles';
import { useLazyGetUsersEngagementQuery } from 'src/redux/features/api/control';
import Preloader from '@components/preloaders/Preloader';
import Pagination from '@shared/components/Pagination';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { UsersEngagementList } from './UsersEngagementList';
import { UsersEngagementFilter } from './UsersEngagementFilter';
import { StyledTitle, StyledTitleWrapper } from './styles';

export const UsersEngagement = () => {
  const [getUsersEngagement, { data, isFetching }] = useLazyGetUsersEngagementQuery();

  const { control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      department: null,
      employee: '',
      page: 1,
    },
  });

  const submitRequest = useCallback(
    (values) => {
      const requestBody = {
        employee: values.employee,
        department_id: values.department?.value,
        page: values.page,
      };
      getUsersEngagement(requestBody);
    },
    [getUsersEngagement],
  );

  const changePage = useCallback(
    (pageNumber) => {
      setValue('page', pageNumber);
      handleSubmit(submitRequest)();
    },
    [setValue, handleSubmit, submitRequest],
  );

  useEffect(() => {
    handleSubmit(submitRequest)();
  }, [handleSubmit, submitRequest]);

  return (
    <>
      <StyledLayout>
        <StyledTitleWrapper>
          <StyledTitle>Вовлеченность</StyledTitle>
        </StyledTitleWrapper>

        <UsersEngagementFilter
          control={control}
          handleSubmit={handleSubmit}
          submitRequest={submitRequest}
          setValue={setValue}
        />

        {isFetching ? (
          <Preloader />
        ) : (
          <>
            {data?.employee_engagement?.employees && (
              <UsersEngagementList employees={data.employee_engagement.employees} />
            )}
          </>
        )}

        {data?.employee_engagement?.page_count && (
          <Pagination id={1} totalPages={data.employee_engagement.page_count} onChange={changePage} defaultValue={1} />
        )}
      </StyledLayout>
    </>
  );
};
