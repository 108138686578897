import { StyledEmployeeCardHeader } from './styles';
import { UserEngagementRow } from './UserEngagementRow';

export const UsersEngagementList = ({ employees }) => {
  return (
    <section>
      <StyledEmployeeCardHeader>
        <div></div>
        <div>Просьбы</div>
        <div>Разделы</div>
        <div>Проекты</div>
        <div>Виртуальные офисы</div>
        <div></div>
      </StyledEmployeeCardHeader>
      {employees?.map((employee) => (
        <UserEngagementRow employee={employee} key={employee.id} />
      ))}
    </section>
  );
};
