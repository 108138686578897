import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  StyledAsideBackwardButton,
  StyledAsideBody,
  StyledAsideHeader,
  StyledAsideLink,
  StyledAsideParentTask,
  StyledAsideTitle,
} from '@components/chat/side-menu/styles';
import { IconButton } from '@shared/components';
import iconAttach from '@assets/images/icons/icon-links.svg';
import iconClose from '@assets/images/icons/close.svg';

import useAuth from '../../../hooks/useAuth';
import UserProfileModalContainer from '../../../windows/profile/UserProfileModalContainer';
import Preloader from '../../preloaders/Preloader';
import { selectGuestProjectRights } from '../../../redux/features/projectsSlice';

import ProjectTree from './ProjectTree';
import MessageAside from './sideMenuComponents/MessageAside';
import { useProjectType } from './useProjectType';

const LinksSidePanel = ({ sideMenu, openSideMenu, projectData }) => {
  const { projectId, taskId } = useParams();
  const auth = useAuth();
  const { t } = useTranslation();
  const [projectTree, showProjectTree] = useState(false);
  const [dataType, setDataType] = useState(null);
  const [panelData, setPanelData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const [userProfile, showUserProfile] = useState();

  const isRequested = useRef(false);

  // reset данных сайд панели, при изменений id в адресной строке
  useEffect(() => {
    if (!taskId && !projectId) return;
    setPanelData(null);
    setErrorLoading(false);
    showProjectTree(false);
    setSidebarDataType(null);
  }, [taskId, projectId]);

  // каждый раз, когда нажимается кнопка открытия панели, запрашиваются данные по текущему проекту/таску
  useEffect(() => {
    if (sideMenu !== 'link' || (!projectId && !taskId)) return;

    if (!projectData || (taskId && projectData.id !== +taskId) || (!taskId && projectData.id !== +projectId)) {
      return;
    }
    const url = `/api/sidebar_links/${taskId ? 'task/' + taskId : 'project/' + projectId}`;

    setIsLoading(true);
    setErrorLoading(false);
    isRequested.current = true;
    axios
      .get(url)
      .then((response) => {
        const chat_messages = response.data.chat_messages;
        setPanelData({
          messages: chat_messages,
          id: projectData.id,
          title: projectData.title,
        });
      })
      .catch(() => {
        setPanelData({});

        setErrorLoading(true);
      })
      .finally(() => {
        isRequested.current = false;
        setIsLoading(false);
      });
  }, [sideMenu, taskId, projectId, projectData]);

  // каждый раз, когда нажимается кнопка открытия панели, производится reset данных
  useEffect(() => {
    if (sideMenu !== 'link' && panelData) {
      setTimeout(() => {
        setPanelData(null);
        setDataType(null);
        showProjectTree(false);
        setSidebarDataType(null);
      }, 1000);
    }
  }, [sideMenu, panelData]);

  useEffect(() => {
    if (!projectData?.type) return;
    setDataType(projectData.type);
  }, [projectData?.type]);

  const [sidebarDataType, setSidebarDataType] = useState();

  const showRelevantTaskInfo = useCallback((taskType, dataId, title) => {
    setPanelData(null);
    showProjectTree(false);
    setErrorLoading(false);
    setIsLoading(true);

    let projectTypeToRequest = null;
    if (taskType === 'request' || taskType === 'rubric') {
      projectTypeToRequest = 'task';
    } else if (taskType === 'project' || taskType === 'office') {
      projectTypeToRequest = 'project';
    }

    if (!projectTypeToRequest || !dataId) return;
    axios
      .get(`/api/sidebar_links/${projectTypeToRequest}/${dataId}`)
      .then((response) => {
        const chat_messages = response.data.chat_messages;

        setPanelData({
          messages: chat_messages,
          id: dataId,
          title: title,
        });

        setIsLoading(false);
        setSidebarDataType(taskType);
        setErrorLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setErrorLoading(true);
      });
  }, []);

  const guestRights = useSelector(selectGuestProjectRights(sidebarDataType || dataType, panelData?.id));

  const projectType = useProjectType({ projectData, sidebarDataType });

  return (
    <>
      {userProfile && <UserProfileModalContainer profileId={userProfile} onClose={() => showUserProfile(null)} />}

      <>
        <StyledAsideHeader>
          <StyledAsideTitle icon={iconAttach} iconsize={18}>
            {t('common.links')}
          </StyledAsideTitle>
          <IconButton
            icon={iconClose}
            size={27}
            onClick={() => {
              setTimeout(() => {
                setPanelData(null);
                setDataType(null);
                showProjectTree(false);
              }, 1000);
              openSideMenu(null);
            }}
          />
        </StyledAsideHeader>
        <StyledAsideBody>
          {errorLoading && <div>{t('ErrorMessages.default')}</div>}

          {projectTree ? (
            <ProjectTree
              update={sideMenu === 'link'}
              showRelevantTaskInfo={showRelevantTaskInfo}
              showProjectTree={showProjectTree}
            />
          ) : (
            <>
              {(!auth?.user?.is_guest || guestRights?.includes('access_children')) && (
                <StyledAsideBackwardButton onClick={() => showProjectTree(true)}>
                  Структура проекта
                </StyledAsideBackwardButton>
              )}

              {projectType.label && panelData?.title && (
                <StyledAsideParentTask>
                  {projectType.label} : {panelData.title}
                </StyledAsideParentTask>
              )}

              {isLoading && <Preloader />}

              {(sidebarDataType === 'project' || sidebarDataType === 'office') && taskId && projectType.textOpen && (
                <StyledAsideLink to={`/projects/${projectId}`}>{projectType.textOpen}</StyledAsideLink>
              )}

              {(sidebarDataType === 'request' || sidebarDataType === 'rubric') &&
                panelData &&
                panelData.id !== +taskId &&
                projectType.textOpen && (
                  <StyledAsideLink to={`/projects/${projectId}/tasks/${panelData.id}`}>
                    {projectType.textOpen}
                  </StyledAsideLink>
                )}

              {panelData &&
                Array.isArray(panelData.messages) &&
                panelData.messages.map((chat_message, i) => (
                  <MessageAside
                    key={i}
                    chat_message={chat_message}
                    showUserProfile={showUserProfile}
                    dataType={dataType || sidebarDataType}
                    dataId={panelData?.id}
                  />
                ))}
            </>
          )}
        </StyledAsideBody>
      </>
    </>
  );
};

export default LinksSidePanel;
