import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar } from '@shared/components/Avatar';
import { Text } from '@shared/components';

import iconMail from '@assets/images/icons/mail.svg';
import iconPhone from '@assets/images/icons/phone.svg';
import iconTelegram from '@assets/images/icons/telegram.svg';
import { formatTelegramHandle } from '@shared/utils/formatTelegramHandle';

import { TeamCard, TeamCardSlash, TeamSocial, TeamSocialIcon, TeamSocialItem, TeamUserWrapper } from './style';

const TeamListItem = ({ id, first_name, last_name, status, email, contacts_phone, position, department, telegram }) => {
  return (
    <TeamCard>
      <span>
        {(position && position) || 'Без должности'}
        <TeamCardSlash>/</TeamCardSlash>
        <span>{(department && department) || 'Отдел не назначен'}</span>
      </span>

      <TeamUserWrapper>
        <Avatar userId={id} type="team-card__userpic" />

        <Link to={`/team/users/${id}`}>
          <Text size={3}>
            {' '}
            {last_name} {first_name}{' '}
          </Text>
          <Text size={1}>{(status && status) || ''}</Text>
        </Link>
      </TeamUserWrapper>

      <TeamSocial>
        <TeamSocialItem href={`mailto:${email}`} target="_blank">
          <TeamSocialIcon icon={iconMail} />
          <Text size={2}>{email}</Text>{' '}
        </TeamSocialItem>

        {contacts_phone && (
          <TeamSocialItem href={`tel:${contacts_phone}`} target="_blank">
            <TeamSocialIcon icon={iconPhone} />
            <Text size={2}>{contacts_phone}</Text>
          </TeamSocialItem>
        )}

        {telegram && (
          <TeamSocialItem href={`https://t.me/${formatTelegramHandle(telegram)}`} target="_blank">
            <TeamSocialIcon icon={iconTelegram} />
            <Text size={2}>{formatTelegramHandle(telegram)}</Text>
          </TeamSocialItem>
        )}
      </TeamSocial>
    </TeamCard>
  );
};

export default TeamListItem;
