import { rtkQueryApi } from './rtkQueryApi';

export const historyApi = rtkQueryApi.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query({
      query: () => '/api/browsing_history',
      providesTags: ['History'],
    }),
  }),
  tagTypes: ['History'],
});

export const { useGetHistoryQuery } = historyApi;
