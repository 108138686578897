import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSearchParams } from 'react-router-dom';

import { Search } from '@shared/components';

import { TeamFilter, TeamFilterCol } from '../style';

import { customSelectStyles, formatSelectOptions, schemaCreateEmployees, viewSelectOptions } from './lib';

let searchExpressionTimeout;

export const TeamFilterForm = memo(TeamFilterFormFC);

function TeamFilterFormFC({ department, position, filterEmployees, view, setView }) {
  const {
    control,
    getValues,
    // setError,
    // resetField,
    // watch,
  } = useForm({
    resolver: yupResolver(schemaCreateEmployees),
    mode: 'onChange',
    // эти поля должны совпадать с настройками в пропсах формы
    defaultValues: {
      first_name: null,
      last_name: null,
      sex: null,
      birthday: null,
      email: null,
      contacts_phone: null,
      date_start: null,
      password: null,
      contacts_address: null,
      contacts_other: null,
      position: null,
      department: null,
      name: '',
    },
  });

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const submit = () => {
    const { position, department, name } = getValues();

    filterEmployees(position?.value, department?.value, name);
  };

  return (
    <TeamFilter>
      <TeamFilterCol>
        {view.value === 'list' && (
          <Controller
            name={position.name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                placeholder={position.placeholder}
                options={formatSelectOptions(position.options, 'title')}
                styles={customSelectStyles}
                onChange={(e) => {
                  field.onChange(e);
                  submit();
                }}
              />
            )}
          />
        )}
        <Controller
          name={department.name}
          control={control}
          render={({ field }) => (
            <Select
              width={'500px'}
              {...field}
              placeholder={department.placeholder}
              options={formatSelectOptions(department.options, 'title')}
              styles={customSelectStyles}
              onChange={(e) => {
                field.onChange(e);
                e.value ? setSearchParams({ department: e.value }) : setSearchParams({});

                submit();
              }}
            />
          )}
        />
      </TeamFilterCol>

      <TeamFilterCol>
        {view.value === 'list' && (
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Search
                {...field}
                placeholder="Поиск по сотрудникам"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearTimeout(searchExpressionTimeout);
                  searchExpressionTimeout = setTimeout(submit, 350);
                }}
              />
            )}
          />
        )}
        <div style={{ gridColumn: '-1/-2' }}>
          <Select
            value={view}
            onChange={(value) => setView(value)}
            options={viewSelectOptions}
            styles={customSelectStyles}
          />
        </div>
      </TeamFilterCol>
    </TeamFilter>
  );
}

export default TeamFilterForm;
