import { Container } from '@shared/components';
import React, { Fragment } from 'react';
import { HrLine } from '@shared/components/Hr/styles';
import { Avatar } from '@shared/components/Avatar';
import { rightsRussianLabels } from 'src/utilize/rights-russian-labels';

import { StyledDepartment, StyledEmployeeCard, StyledName } from './styles';

export const UsersRightsList = ({ employees }) => {
  return (
    <section>
      {employees?.map((employee) => (
        <Fragment key={employee.id}>
          <StyledEmployeeCard>
            <Container gap={'6px'}>
              <Avatar userId={employee.id} />
              <Container direction={'column'}>
                <StyledName>{`${employee.last_name || ''} ${employee.first_name || ''}`}</StyledName>
                <StyledDepartment>{employee.department}</StyledDepartment>
              </Container>
            </Container>
            <div>
              {employee.rights.map((right, index, array) => (
                <>{`${rightsRussianLabels[right.access] || right.access}` + (index === array.length - 1 ? '' : ', ')}</>
              ))}
            </div>
          </StyledEmployeeCard>
          <HrLine />
        </Fragment>
      ))}
    </section>
  );
};
