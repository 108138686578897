import axios from 'axios';

import { notificationTypes } from './notifications.types';

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const PushLocalStorageTypes = {
  key: 'is_push_subscribed',
  subscribed: 'subscribed',
  loggedOut: 'loggedOut',
};

export const configurePushSubscription = async (setIsSubscribed, showSnackbar, setIsLoading) => {
  if (!('serviceWorker' in navigator) || !('PushManager' in window) || window.Notification?.permission !== 'granted')
    return;

  let registeredServiceWorker, publicKey, isError;
  if (typeof setIsLoading === 'function') setIsLoading(true);
  const promises = [
    navigator.serviceWorker.ready
      .then((response) => (registeredServiceWorker = response))
      .catch(() => (isError = true)),

    axios
      .get('api/public_key')
      .then((response) => (publicKey = response.data.publicKey))
      .catch(() => (isError = true)),
  ];
  await Promise.all(promises);

  if (isError) {
    if (typeof showSnackbar === 'function') showSnackbar('Возникла ошибка при подписке на push уведомления');
    if (typeof setIsLoading === 'function') setIsLoading(false);
    return;
  }

  // Create a new subscription
  try {
    const subscription = await registeredServiceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicKey),
    });

    await axios.post('api/push_notifications/add', subscription);
    if (typeof setIsSubscribed === 'function') setIsSubscribed(true);
    localStorage.setItem(PushLocalStorageTypes.key, PushLocalStorageTypes.subscribed);
  } catch (e) {
    if (e?.data?.message?.includes('Duplicate')) return;
    if (typeof showSnackbar === 'function') showSnackbar('Возникла ошибка при подписке на push уведомления');
  }

  if (typeof setIsLoading === 'function') setIsLoading(false);
};

export const unsubscribeBrowserPush = async () => {
  return navigator.serviceWorker.ready
    .then((serviceWorker) => serviceWorker.pushManager.getSubscription())
    .then(async (subscription) => {
      if (subscription) {
        await subscription.unsubscribe();
      }
    });
};

export const renderNotificationType = (notificationData) => {
  if (!notificationTypes[notificationData.data_type]) return 'новое уведомление';

  if (notificationTypes[notificationData.data_type][`${notificationData.entity_type}_text`]) {
    return notificationTypes[notificationData.data_type][`${notificationData.entity_type}_text`];
  } else if (notificationTypes[notificationData.data_type].text) {
    return notificationTypes[notificationData.data_type].text;
  }

  return 'новое уведомление';
};
