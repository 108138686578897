import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { StyledDeadline } from './style';
import ChangeDeadlineModal from './ChangeDeadlineModal';

export const RequestDeadline = memo(RequestDeadlineFC);

function RequestDeadlineFC({ currentStatus, getTaskData, taskIdToEdit, mlAuto }) {
  const [changeDeadline, setChangeDeadline] = useState(false);

  const currentRequestDeadline = useSelector((state) => state.projects.currentRequestDeadline);

  const momentDate = useMemo(() => {
    if (typeof currentRequestDeadline === 'string') {
      return moment(currentRequestDeadline);
    }
  }, [currentRequestDeadline]);

  return (
    <>
      {momentDate?.isValid() && (
        <StyledDeadline
          $hide={currentStatus.isActive === 'finished' || currentStatus.isActive === 'canceled'}
          $mlAuto={mlAuto}
          onClick={() => setChangeDeadline(true)}
        >
          Дедлайн: {momentDate.format('DD.MM.YYYY')}
        </StyledDeadline>
      )}

      {/* окно изменения сроков просьбы */}
      {changeDeadline && (
        <ChangeDeadlineModal
          close={() => setChangeDeadline(false)}
          taskIdToEdit={taskIdToEdit}
          getTaskData={getTaskData}
        />
      )}
    </>
  );
}
