import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import iconClose from '@assets/images/icons/close.svg';
import { IconButton } from '@shared/components';
import { useGetHistoryQuery } from 'src/redux/features/api/history';
import { showLocalDateTime } from 'src/utilize/helper-functions';
import { useNavigate } from 'react-router-dom';
import Preloader from '@components/preloaders/Preloader';
import { useDispatch } from 'react-redux';
import { setHistorySidebarX } from 'src/redux/features/commonSlice';

export const HistorySidebar = ({ onClose }) => {
  const { data, isFetching, isLoading } = useGetHistoryQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [isMobile, setIsMobile] = useState(false);

  const sidebarRef = useRef(null);

  const isHistoryLoading = isFetching || isLoading;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getEntityDetails = useCallback((data) => {
    if (!data) return { title: '', entityType: '' };

    const entityTypes = {
      project: 'Проект',
      office: 'Виртуальный офис',
      request: 'Просьбы',
      rubric: 'Раздел',
    };

    const titles = {
      project: data.project?.title,
      office: data.office?.title,
      request: data.request?.title,
      rubric: data.rubric?.title,
    };

    const entityUrls = {
      project: `/projects/${data.project?.id}`,
      office: `/projects/${data.office?.id}`,
      request: `/projects/${data.request?.project_id}/tasks/${data.request?.id}`,
      rubric: `/projects/${data.rubric?.project_id}/tasks/${data.rubric?.id}`,
    };

    return {
      title: titles[data.entity_type] || '',
      entityType: entityTypes[data.entity_type] || '',
      entityUrl: entityUrls[data.entity_type] || '',
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
      if (sidebarRef.current) {
        dispatch(setHistorySidebarX(sidebarRef.current.getBoundingClientRect().x));
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <SidebarContainer ref={sidebarRef}>
      <SidebarHeader>
        <SidebarTitle>Недавно просмотренные</SidebarTitle>

        <IconButton icon={iconClose} size={27} onClick={onClose} />
      </SidebarHeader>

      {isHistoryLoading && <Preloader />}

      <CardsList>
        {data?.browsing_history.map((item) => {
          const { title, entityType } = getEntityDetails(item);
          return (
            <HistoryCard
              key={item.id}
              onClick={() => {
                navigate(getEntityDetails(item).entityUrl);
                isMobile && onClose();
              }}
            >
              <StyledTitle>{title}</StyledTitle>
              <StyledText>{entityType}</StyledText>
              <StyledText>{showLocalDateTime(item.date_created)}</StyledText>
            </HistoryCard>
          );
        })}
      </CardsList>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  width: 300px;
  min-width: 300px;

  overflow-y: scroll;
  background: #f0f0f0;
  padding: 52px 20px 20px 20px;

  z-index: 10;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 1150px) {
    width: 100%;
    position: fixed;

    top: 0;
    bottom: 0;
    left: 0;
  }
`;

const SidebarHeader = styled.div`
  position: sticky;
  top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 14px;

  height: 52px;

  background: #f0f0f0;

  /* background-color: yellow; */
`;

const SidebarTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const CardsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HistoryCard = styled.div`
  display: flex;
  background-color: #fff;
  padding: 10px 14px;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #000;
`;

const StyledText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #9f9f9f;
`;
