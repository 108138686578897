import React, { useRef, useState, memo, useCallback, useEffect, useMemo, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ProjectFormCreateEdit from 'src/windows/projects/project-form-create-edit/ProjectFormCreateEdit';
import { useSocketContext } from 'src/contexts/SocketContext';
import { formatDateWithDots, getFormatCalendarData } from '@shared/components/CalendarForm/CalendarForm';
import useAuth from 'src/hooks/useAuth';
import { useOutsideTrigger } from 'src/utilize/helper-functions';
import CreateEditGuestModal from 'src/windows/profile/CreateEditGuestModal';
import ConfirmAction from 'src/components/warnings/ConfirmAction';
import CreateTaskModal from 'src/components/tasks/CreateTaskModal';
import { IconButton, Term } from '@shared/components';
import iconLock from '@assets/images/icons/lock.svg';
import iconUnlock from '@assets/images/icons/unlocked.svg';
import iconInfo from '@assets/images/icons/icon-info.svg';
import { colors } from 'src/variables';
import {
  ProjectStatus,
  ProjectStatusWithText,
  StyledChatHeader,
  StyledChatHeaderCol,
  StyledChatHeaderTitle,
  StyledChatHeaderWrapper,
  StyledChatMenu,
  StyledChatMenuItem,
  StyledChatTitleWrapper,
  StyledTermWrapper,
} from '@components/chat/styles';
import ModalPortal from '@shared/components/Modal/ModalPortal';
import HeaderRequestPanel from '@components/chat/request-panel/HeaderRequestPanel';
import { useWindowWidth } from '@shared/hooks/useWindowWidth';
import ChangeExecutorModal from '@components/chat/request-panel/ChangeExecutorModal';
import ChangeResponsibleModal from '@components/chat/request-panel/ChangeResponsibleModal';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentProject } from 'src/redux/features/projectsSlice';

import SnackbarContext from '../../../contexts/SnackbarContext';
import useMenuPosition from '../../../hooks/useCoordinates/useMenuPosition';
import { RequestDeadline } from '../request-panel/RequestDeadline';

import SingleProjectHeaderMenu from './SingleProjectHeaderMenu';
import SingleProjectHeaderMessageBasis from './SingleProjectHeaderMessageBasis';
import { PinnedMessages } from './PinnedMessages/PinnedMessages';

// статусы проекта
export const status = {
  in_progress: {
    color: colors.main,
    text: 'В работе',
  },
  finished: {
    color: colors.success,
    text: 'Завершена',
  },
  overdue: {
    color: colors.warning,
    text: 'Просрочена',
  },
  created: {
    color: colors.disabled,
    text: 'Создана',
  },
  under_review: {
    color: colors.main,
    text: 'На проверке',
  },
  canceled: {
    color: colors.errorLight,
    text: 'Отменена',
  },
  on_clarification: {
    color: colors.warningSecondary,
    text: 'На уточнении',
  },
};

export default memo(SingleProjectHeader);

function SingleProjectHeader({
  projectData,
  getProjectData,
  showSubtaskAddModal,
  setSubtaskTypeModal,
  pinnedMessagesList,
  hideHeader,
}) {
  const auth = useAuth();

  // статусы проекта
  const currentProjectStatus = useSelector((state) => state.projects.currentProjectStatus);
  // const [projectStatus, setProjectStatus] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateCurrentProject({
        currentProjectStatus: projectData?.status,
        currentRequestDeadline: projectData?.date_finish,
        currentRequestExecutorId: projectData?.executor_id,
        currentProjectResponsibleId: projectData?.responsible_id,
      }),
    );
  }, [projectData?.status, projectData?.date_finish, projectData?.executor_id, projectData?.responsible_id]);

  const currentStatus = useMemo(
    () => ({ ...status[currentProjectStatus], isActive: currentProjectStatus }),
    [currentProjectStatus],
  );

  const { projectId, taskId } = useParams();

  const { socket, addToRoom, leaveRoom, startTask, finishTask, unfinishTask, onTaskStatusChange } = useSocketContext();
  const [editModal, setEditModal] = useState();
  const [isRequestPanelOpen, setIsRequestPanelOpen] = useState(false);
  const windowWidth = useWindowWidth();
  const breakpoint = 992;
  const [isMobile, setIsMobile] = useState(false);

  const [changeExecutor, setChangeExecutor] = useState(false);
  const [changeResponsible, setChangeResponsible] = useState(false);
  // const [changeDeadline, setChangeDeadline] = useState(false);

  useEffect(() => {
    if (windowWidth >= breakpoint) {
      setIsMobile(false);
      setIsRequestPanelOpen(true);
    } else {
      setIsMobile(true);
    }
  }, [windowWidth]);

  const locked = useMemo(() => projectData?.locked, [projectData]);

  // войти в socket комнату для отслеживвания статуса проекта/таска
  useEffect(() => {
    if (socket?.connected) {
      addToRoom('project_task_status_changed');

      return () => leaveRoom('project_task_status_changed');
    }
  }, [addToRoom, leaveRoom, socket?.connected]);

  // проверить есть ли у юзера права на изменение статуса проекта/таска
  const userHasRights = useMemo(() => {
    if (!auth?.user?.id || !auth?.rightTypes) return false;
    if (auth?.isUserRightful()) return true;
    if (auth.user.id === projectData?.creator_id) return true;
    if (auth.user.id === projectData?.responsible_id) return true;
    return false;
  }, [auth, projectData]);

  const currentRequestExecutorId = useSelector((state) => state.projects.currentRequestExecutorId);
  const currentProjectResponsibleId = useSelector((state) => state.projects.currentProjectResponsibleId);

  const usersRoles = useMemo(
    () => ({
      isExecutor: auth.user.id === currentRequestExecutorId,
      isResponsible: auth.user.id === currentProjectResponsibleId,
    }),
    [currentRequestExecutorId, currentProjectResponsibleId, auth?.user?.id],
  );

  // const [optionsMenu, toggleOptionsMenu] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  // для показа окна создания гостевого доступа
  const [guestCreateModal, showGuestCreateModal] = useState(false);

  const clearShowMenu = useCallback(() => {
    setShowInfo(false);
  }, []);

  // для закрытия всплывающих меню при нажатии вне этих меню
  const buttonInfoRef = useRef(null);
  const menuInfoRef = useRef(null);

  const coordinates = useMenuPosition({
    triggerRef: buttonInfoRef,
    tooltipRef: menuInfoRef,
    isVisible: showInfo,
    setIsVisible: setShowInfo,
    position: 'left-top',
  });

  useOutsideTrigger([buttonInfoRef], clearShowMenu, showInfo);

  // отправлять socket события на сервер при изменении статуса проекта текущим юзером
  const manageTaskStatus = (action) => {
    const data = {};
    taskId ? (data.task_id = projectData.id) : (data.project_id = projectData.id);

    if (action === 'start') startTask(data);
    else if (action === 'finish') finishTask(data);
    else if (action === 'unfinish') unfinishTask(data);
    else if (action === 'on_clarification') test(data);
  };

  // socket callback для отслеживания изменений статуса проекта
  const statusChangeCallback = useCallback(
    (data) => {
      if ((taskId && projectData?.id === data.task_id) || (!taskId && projectData.id === data.project_id)) {
        dispatch(updateCurrentProject({ currentProjectStatus: projectData?.status }));
      }
    },
    [taskId, projectData?.id, dispatch],
  );

  // слушать socket событие об изменении статуса проекта
  useEffect(() => {
    onTaskStatusChange(statusChangeCallback);
  }, [onTaskStatusChange, statusChangeCallback]);

  const [confirmModal, showConfirmModal] = useState(false);

  const closeEditModal = useCallback(() => setEditModal(false), []);

  const projectEditData = useMemo(() => ({ editable: { projectId: projectData.id } }), [projectData?.id]);
  const swiperRef = useRef(null);

  const { showSnackbar } = useContext(SnackbarContext);

  const changeTaskStatus = (action) => {
    if (auth) {
      const submitData = {
        task_id: projectData.id,
        type: 'request',
        action: action,
        additional_params: action === 'cancel' ? { date_finish: getFormatCalendarData(new Date()) } : {},
      };

      axios
        .patch('/api/change_task_status', submitData)
        .then((res) => {
          dispatch(updateCurrentProject({ currentProjectStatus: res.data.result.status }));
          showSnackbar('Статус изменен', 'success');
        })
        .catch(() => {
          showSnackbar('Возникла ошибка при изменении статуса задачи');
        });
      return;
    }
  };

  return (
    <>
      <StyledChatHeaderWrapper>
        <StyledChatHeader>
          {!hideHeader && (
            <>
              <StyledChatHeaderCol>
                <StyledChatTitleWrapper>
                  <IconButton icon={locked ? iconLock : iconUnlock} />
                  <div style={{ position: 'relative' }}>
                    {currentProjectStatus && status[currentProjectStatus] && (
                      <>
                        {projectData.type === 'request' ? (
                          !isMobile && (
                            <ProjectStatusWithText $color={status[currentProjectStatus].color}>
                              {status[currentProjectStatus].text}
                            </ProjectStatusWithText>
                          )
                        ) : (
                          <ProjectStatus color={status[currentProjectStatus].color} />
                        )}
                      </>
                    )}
                  </div>

                  <StyledChatHeaderTitle>{`${projectData?.title}`}</StyledChatHeaderTitle>

                  {projectData.type !== 'request' &&
                    (projectData?.date_start || projectData?.date_finish || projectData.base_chat_message_id) && (
                      <div style={{ position: 'relative' }}>
                        <IconButton
                          size={20}
                          icon={iconInfo}
                          onClick={() => setShowInfo((prev) => !prev)}
                          $ref={buttonInfoRef}
                          style={{ verticalAlign: 'middle' }}
                        />
                        <ModalPortal>
                          <StyledChatMenu $active={showInfo} style={{ ...coordinates }} ref={menuInfoRef}>
                            {(projectData?.date_start || projectData?.date_finish) && (
                              <StyledChatMenuItem>
                                <StyledTermWrapper>
                                  {projectData?.date_start && (
                                    <Term>{formatDateWithDots(projectData?.date_start)}</Term>
                                  )}
                                  -
                                  {projectData?.date_finish && (
                                    <Term>{formatDateWithDots(projectData.date_finish)}</Term>
                                  )}
                                </StyledTermWrapper>
                              </StyledChatMenuItem>
                            )}
                            {projectData.base_chat_message_id && (
                              <StyledChatMenuItem>
                                <SingleProjectHeaderMessageBasis projectData={projectData} />
                              </StyledChatMenuItem>
                            )}
                          </StyledChatMenu>
                        </ModalPortal>
                      </div>
                    )}
                </StyledChatTitleWrapper>
                {/*дедлайн для десктопа*/}
                {projectData.type === 'request' &&
                  (userHasRights || projectData?.executor_id === auth?.user?.id) &&
                  !isMobile && (
                    <RequestDeadline
                      currentStatus={currentStatus}
                      mlAuto
                      taskIdToEdit={projectData?.id}
                      getTaskData={getProjectData}
                    />
                  )}
              </StyledChatHeaderCol>
              <StyledChatHeaderCol style={{ marginLeft: 'auto' }}>
                {auth?.user && !auth.user.is_guest && projectData && (
                  <SingleProjectHeaderMenu
                    projectStatus={currentProjectStatus}
                    taskType={projectData?.type}
                    userHasRights={userHasRights}
                    setEditModal={setEditModal}
                    taskId={taskId}
                    projectId={projectId}
                    showSubtaskAddModal={showSubtaskAddModal}
                    setSubtaskTypeModal={setSubtaskTypeModal}
                    showConfirmModal={showConfirmModal}
                    showGuestCreateModal={showGuestCreateModal}
                    canConvert={projectData?.canConvert}
                    canDelete={projectData?.canDelete}
                    canChangeCreator={projectData?.canChangeCreator}
                    manageTaskStatus={manageTaskStatus}
                    parent_task_id={projectData?.parent_task_id}
                  />
                )}
              </StyledChatHeaderCol>
            </>
          )}

          {Array.isArray(pinnedMessagesList) && pinnedMessagesList.length > 0 && (
            <PinnedMessages hideHeader={hideHeader} pinnedMessagesList={pinnedMessagesList} projectData={projectData} />
          )}

          {(userHasRights ||
            currentRequestExecutorId === auth?.user?.id ||
            currentProjectResponsibleId === auth?.user?.id) &&
            projectData.type === 'request' && (
              <HeaderRequestPanel
                projectData={projectData}
                getProjectData={getProjectData}
                isRequestPanelOpen={isRequestPanelOpen}
                setIsRequestPanelOpen={setIsRequestPanelOpen}
                isMobile={isMobile}
                currentStatus={currentStatus}
                usersRoles={usersRoles}
                setChangeExecutor={setChangeExecutor}
                setChangeResponsible={setChangeResponsible}
                changeTaskStatus={changeTaskStatus}
              />
            )}
        </StyledChatHeader>
      </StyledChatHeaderWrapper>

      {/* окно изменения исполнителя просьбы */}
      {changeExecutor && (
        <ChangeExecutorModal
          close={() => setChangeExecutor(false)}
          taskIdToEdit={projectData.id}
          getTaskData={getProjectData}
        />
      )}

      {/* окно изменения ответственного/проверяющего просьбы */}
      {changeResponsible && (
        <ChangeResponsibleModal
          close={() => setChangeResponsible(false)}
          taskIdToEdit={projectData.id}
          getTaskData={getProjectData}
          changeTaskStatus={changeTaskStatus}
        />
      )}

      {/* окно подтверждения завершении проекта */}
      {confirmModal && (
        <ConfirmAction
          confirm={() => {
            manageTaskStatus('finish');
            showConfirmModal(false);
          }}
          confirmButtonText="Завершить"
          cancel={() => showConfirmModal(false)}
          actionText={`Вы уверены, что хотите завершить ${taskId ? 'задачу' : 'проект'}?`}
        />
      )}

      {/* окно создания гостевого доступа */}
      {guestCreateModal && (
        <CreateEditGuestModal
          projectData={projectData}
          dataType={taskId ? 'task' : 'project'}
          close={() => showGuestCreateModal(false)}
        />
      )}

      {/* окно редактирования */}
      {editModal && (
        <>
          {projectData?.project_id || projectData?.parent_task_id ? (
            <CreateTaskModal
              close={closeEditModal}
              taskIdToEdit={projectData.id}
              getTaskData={getProjectData}
              modalType={projectData.type}
            />
          ) : (
            <ProjectFormCreateEdit
              onClose={closeEditModal}
              data={projectEditData}
              getProjectData={getProjectData}
              modalType={projectData.type}
            />
          )}
        </>
      )}
    </>
  );
}
