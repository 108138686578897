import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import Preloader from '@components/preloaders/Preloader';
import { getUsers } from 'src/redux/features/usersSlice';
import { useSocketContext } from 'src/contexts/SocketContext';
import UserProfileModalContainer from 'src/windows/profile/UserProfileModalContainer';

import TeamFilterForm from './TeamFilter';
import TeamList from './TeamList';
import TeamTree from './TeamTree';
import { Team } from './style';

// основная часть контента для страницы team
const TeamMain = () => {
  const { socket, addToRoom, leaveRoom, onDepartmentChange, offDepartmentChange } = useSocketContext();
  const dispatch = useDispatch();
  const [view, setView] = useState({ value: 'list', label: 'Списком' });

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setSearchParams({});
  }, []);

  useEffect(() => {
    if (socket?.connected) {
      if (dispatch) {
        addToRoom('departments_list');

        onDepartmentChange((_data, action) => {
          if (action === 'added' || action === 'edited') dispatch(getUsers());
        });

        return () => {
          leaveRoom('departments_list');
          offDepartmentChange();
        };
      }
    }
  }, [socket, onDepartmentChange, dispatch, addToRoom, leaveRoom, offDepartmentChange]);

  const { employees, departments, positions, isLoading } = useSelector((state) => state.users);
  const [filteredEmployees, setFilteredEmployees] = useState();

  const filterEmployees = useCallback(
    (position, dept, searchExpression) => {
      let filteredValues;
      filteredValues = employees.filter((emp) => {
        if (position && emp.position_id !== +position) return false;
        if (dept && emp.department_id !== +dept) return false;

        let doesMatch = true;
        const searchTerms = searchExpression?.split(' ').map((searchTerm) => searchTerm.toLowerCase());

        if (searchTerms?.length) {
          let isFound = false;
          let loweredSearchableText = emp.first_name ? emp.first_name.toLowerCase() : '';
          loweredSearchableText += emp.last_name ? emp.last_name.toLowerCase() : '';
          loweredSearchableText += emp.telegram ? emp.telegram.toLowerCase() : '';
          loweredSearchableText += emp.email ? emp.email.toLowerCase() : '';

          for (const searchTerm of searchTerms) {
            if (loweredSearchableText.includes(searchTerm)) {
              isFound = true;
              break;
            }
          }

          // фильтр по номеру телефона
          if (emp.contacts_phone && !/\p{L}/u.test(searchExpression)) {
            const phoneOnlyNumbers = emp.contacts_phone.replace(/[^0-9]/g, '');
            const searchExpressionNumbers = searchExpression.replace(/[^0-9]/g, '');
            if (searchExpressionNumbers && phoneOnlyNumbers.includes(searchExpressionNumbers)) {
              isFound = true;
            }
          }

          doesMatch = isFound;
        }

        return doesMatch;
      });
      setFilteredEmployees(filteredValues);
    },
    [employees],
  );

  const { departmentFilter, positionFilter, sortingOptions } = useMemo(() => {
    const departmentFilter = {
      name: 'department',
      icon: true,
      placeholder: 'Все отделы',
      type: 'text',
      options: departments ? [{ title: 'Все отделы', id: '' }, ...departments] : [{ title: 'Все отделы', id: '' }],
    };

    const positionFilter = {
      name: 'position',
      icon: true,
      label: 'Должность',
      placeholder: 'Все должности',
      type: 'select',
      options: positions ? [{ title: 'Все должности', id: '' }, ...positions] : [{ title: 'Все должности', id: '' }],
    };

    const sortingOptions = {
      name: 'position_id',
      icon: true,
      label: 'Сортировать по',
      placeholder: 'Сортировать по',
      // label: t(`${translateKey}.position`),
      type: 'select',
      options: ['по дате создания', 'по дате изменения'],
    };

    return { departmentFilter, positionFilter, sortingOptions };
  }, [departments, positions]);

  return (
    <>
      <Team>
        {isLoading && <Preloader />}

        <TeamFilterForm
          department={departmentFilter}
          position={positionFilter}
          sorting={sortingOptions}
          filterEmployees={filterEmployees}
          view={view}
          setView={setView}
        />
        {employees && view.value === 'list' && <TeamList data={filteredEmployees || employees}></TeamList>}
        {employees && view.value === 'tree' && <TeamTree />}
      </Team>
      {userId && <UserProfileModalContainer profileId={userId} onClose={() => navigate('/team/users')} />}
    </>
  );
};

export default TeamMain;
