import { Container, Modal, Text } from '@shared/components';
import { Avatar } from '@shared/components/Avatar';
import { Link } from 'react-router-dom';
import { useGetUserEngagementDetailsQuery } from 'src/redux/features/api/control';
import Preloader from '@components/preloaders/Preloader';
import moment from 'moment';

import { StyledDeadline, StyledModalContainer, StyledProjectsGrid, StyledRequestsGrid } from './styles';

export function UserEngagementDetailsModal({ onClose, userId }) {
  const { data, isFetching } = useGetUserEngagementDetailsQuery(userId, { skip: !userId });

  return (
    <Modal title="Вовлеченность" withoutButtons onClose={onClose}>
      <Container align="center">
        <Avatar userId={userId} />
        <Text as="span" size={3}>
          {`${data?.engagementByEmployee.first_name || ''} ${data?.engagementByEmployee.last_name || ''}`}
        </Text>
      </Container>
      {isFetching && <Preloader />}
      {data?.engagementByEmployee && (
        <StyledModalContainer>
          <StyledProjectsGrid>
            {Array.isArray(data.engagementByEmployee.project) && data.engagementByEmployee.project.length > 0 && (
              <div>
                <Text as="div" size={5}>
                  Проекты
                </Text>
                {data.engagementByEmployee.project.map((project) => (
                  <Link to={`/projects/${project.id}`}>{project.title}</Link>
                ))}
              </div>
            )}
            <div>
              {Array.isArray(data.engagementByEmployee.office) && data.engagementByEmployee.office.length > 0 && (
                <div>
                  <Text as="div" size={5}>
                    Виртуальные офисы
                  </Text>
                  {data.engagementByEmployee.office.map((office) => (
                    <Link to={`/projects/${office.id}`}>{office.title}</Link>
                  ))}
                </div>
              )}
            </div>
          </StyledProjectsGrid>

          {data.engagementByEmployee.request && (
            <>
              <br />
              <Text size={5}>Просьбы</Text>
              {Array.isArray(data.engagementByEmployee.request.creator) &&
                data.engagementByEmployee.request.creator.length > 0 && (
                  <>
                    <br />
                    <Text size={3}>Создатель</Text>
                    <StyledRequestsGrid>
                      {data.engagementByEmployee.request.creator.map((request) => (
                        <>
                          <Link to={`/projects/${request.root_project}/tasks/${request.id}`}>{request.title}</Link>

                          <Link to={`/projects/${request.root_project}`}>проект: {request.project_title}</Link>

                          <StyledDeadline $isOverdue={request.is_overdue}>
                            {request.date_finish && moment(request.date_finish).format('DD.MM.YYYY')}
                          </StyledDeadline>
                        </>
                      ))}
                    </StyledRequestsGrid>
                  </>
                )}

              {Array.isArray(data.engagementByEmployee.request.responsible) &&
                data.engagementByEmployee.request.responsible.length > 0 && (
                  <>
                    <br />
                    <Text size={3}>Ответсвенный</Text>
                    <StyledRequestsGrid>
                      {data.engagementByEmployee.request.responsible.map((request) => (
                        <>
                          <Link to={`/projects/${request.root_project}/tasks/${request.id}`}>{request.title}</Link>

                          <Link to={`/projects/${request.root_project}`}>проект: {request.project_title}</Link>

                          <StyledDeadline $isOverdue={request.is_overdue}>
                            {request.date_finish && moment(request.date_finish).format('DD.MM.YYYY')}
                          </StyledDeadline>
                        </>
                      ))}
                    </StyledRequestsGrid>
                  </>
                )}

              {Array.isArray(data.engagementByEmployee.request.executor) &&
                data.engagementByEmployee.request.executor.length > 0 && (
                  <>
                    <br />
                    <Text size={3}>Исполнитель</Text>
                    <StyledRequestsGrid>
                      {data.engagementByEmployee.request.executor.map((request) => (
                        <>
                          <Link to={`/projects/${request.root_project}/tasks/${request.id}`}>{request.title}</Link>

                          <Link to={`/projects/${request.root_project}`}>проект: {request.project_title}</Link>

                          <StyledDeadline $isOverdue={request.is_overdue}>
                            {request.date_finish && moment(request.date_finish).format('DD.MM.YYYY')}
                          </StyledDeadline>
                        </>
                      ))}
                    </StyledRequestsGrid>
                  </>
                )}

              {Array.isArray(data.engagementByEmployee.request.member) &&
                data.engagementByEmployee.request.member.length > 0 && (
                  <>
                    <br />
                    <Text size={3}>Участник</Text>
                    <StyledRequestsGrid>
                      {data.engagementByEmployee.request.member.map((request) => (
                        <>
                          <Link to={`/projects/${request.root_project}/tasks/${request.id}`}>{request.title}</Link>

                          <Link to={`/projects/${request.root_project}`}>проект: {request.project_title}</Link>

                          <StyledDeadline $isOverdue={request.is_overdue}>
                            {request.date_finish && moment(request.date_finish).format('DD.MM.YYYY')}
                          </StyledDeadline>
                        </>
                      ))}
                    </StyledRequestsGrid>
                  </>
                )}
            </>
          )}

          {data.engagementByEmployee.rubric && (
            <>
              <br />
              <Text size={5}>Разделы</Text>
              {Array.isArray(data.engagementByEmployee.rubric.creator) &&
                data.engagementByEmployee.rubric.creator.length > 0 && (
                  <>
                    <br />
                    <Text size={3}>Создатель</Text>
                    <StyledRequestsGrid>
                      {data.engagementByEmployee.rubric.creator.map((rubric) => (
                        <>
                          <Link to={`/projects/${rubric.root_project}/tasks/${rubric.id}`}>{rubric.title}</Link>

                          <Link to={`/projects/${rubric.root_project}`}>проект: {rubric.project_title}</Link>

                          <span></span>
                        </>
                      ))}
                    </StyledRequestsGrid>
                  </>
                )}

              {Array.isArray(data.engagementByEmployee.rubric.member) &&
                data.engagementByEmployee.rubric.member.length > 0 && (
                  <>
                    <br />
                    <Text size={3}>Участник</Text>
                    <StyledRequestsGrid>
                      {data.engagementByEmployee.rubric.member.map((rubric) => (
                        <>
                          <Link to={`/projects/${rubric.root_project}/tasks/${rubric.id}`}>{rubric.title}</Link>

                          <Link to={`/projects/${rubric.root_project}`}>проект: {rubric.project_title}</Link>

                          <span></span>
                        </>
                      ))}
                    </StyledRequestsGrid>
                  </>
                )}
            </>
          )}
        </StyledModalContainer>
      )}
    </Modal>
  );
}
