import { memo, useCallback, useMemo } from 'react';
import { Button, Label } from '@shared/components';
import plusIcon from '@assets/images/icons/plus.svg';
import { getOptions } from 'src/components/form/select/userSelectComponents';
import useAuth from 'src/hooks/useAuth';
import { object, func } from 'prop-types';

import PollTarget from './PollTarget';

import { AddDepartmentButton } from './AddDepartmentButton';
import { StyledButtonsWrapper } from './styles';

export const PollTargetSection = memo(PollTargetSectionFC);

PollTargetSectionFC.propTypes = {
  control: object,
  getValues: func,
  setValue: func,
};

function PollTargetSectionFC({ control, getValues, setValue, pollData }) {
  const auth = useAuth();

  const employeeOptions = useMemo(
    () => getOptions({ users: pollData?.employees, token: auth.token }),
    [pollData?.employees, auth?.token],
  );

  const addDepartments = useCallback(
    (selectedOptions) => {
      if (!Array.isArray(selectedOptions?.departments) || !Array.isArray(employeeOptions)) return;

      const pollMembers = getValues('poll_members');
      let selectedEmployeeIds;
      if (Array.isArray(pollMembers)) {
        selectedEmployeeIds = new Set(pollMembers.map((pollMember) => pollMember.value.id));
      } else selectedEmployeeIds = new Set();

      selectedOptions.departments.forEach((department) => {
        department.employees?.forEach((employee) => selectedEmployeeIds.add(employee.id));
      });
      const optionsToAdd = employeeOptions.filter((option) => selectedEmployeeIds.has(option.value.id));

      setValue('poll_members', optionsToAdd, { shouldDirty: true, shouldValidate: true });
    },
    [getValues, employeeOptions],
  );

  const addAllChatMembers = () => {
    if (!Array.isArray(pollData?.chat_members)) return;

    const pollMembers = getValues('poll_members');
    let selectedEmployeeIds;
    if (Array.isArray(pollMembers)) {
      selectedEmployeeIds = new Set(pollMembers.map((pollMember) => pollMember.value.id));
    } else selectedEmployeeIds = new Set();

    pollData.chat_members.forEach((employee) => selectedEmployeeIds.add(employee.id));
    const optionsToAdd = employeeOptions.filter((option) => selectedEmployeeIds.has(option.value.id));

    setValue('poll_members', optionsToAdd, { shouldDirty: true, shouldValidate: true });
  };

  return (
    <div>
      {pollData && (
        <>
          <Label htmlFor="poll_target">
            Кого опрашиваем <sup>*</sup>
          </Label>
          <PollTarget control={control} employeeOptions={employeeOptions} />
          <StyledButtonsWrapper
            $threeColumns={!Array.isArray(pollData?.chat_members) || !pollData?.chat_members.length}
          >
            <AddDepartmentButton departments={pollData.departments} addDepartments={addDepartments} />

            {Array.isArray(pollData?.chat_members) && pollData?.chat_members.length > 0 && (
              <Button size="sm" position="left" icon={plusIcon} onClick={addAllChatMembers}>
                Всех участников чата
              </Button>
            )}
            <Button
              size="sm"
              position="left"
              icon={plusIcon}
              onClick={() => {
                if (!Array.isArray(employeeOptions)) return;
                setValue('poll_members', employeeOptions, { shouldDirty: true, shouldValidate: true });
              }}
            >
              Всех сотрудников
            </Button>
            <Button
              size="sm"
              color="red"
              onClick={() => {
                setValue('poll_members', [], { shouldDirty: true, shouldValidate: true });
              }}
            >
              Удалить всех
            </Button>
          </StyledButtonsWrapper>{' '}
        </>
      )}
    </div>
  );
}
